<template>
  <div class="media-library-sidebar" v-touch:swipe="handleSwipe">
    <!--  Mobile Only -->
    <!-- <div v-if="isMobile" class="breadcrumb-bar">
      <Panel>
        <Breadcrumbs
          :label="route.name || 'media'"
          @navigate-back="$emit('navigate-back')"
        />
      </Panel>
    </div> -->

    <div class="menu-container no-scrollbar">
      <!-- Desktop Only -->
      <div v-if="!isMobile" class="desktop-header">
        <p class="w-full font-bold text-sm">
          {{ $capitalize(route.name || "media") }}
        </p>
        <div class="flex flex-row gap-4">
          <IconToolbar :tools="toolbarTools" class="-mr-2">
            <template #filter>
              <URadioGroup
                v-model="listSortSelectedOption"
                :options="listSortOptions"
              />
              <UDivider />
              <VerticalNavigation :links="listOptionToolbarTools" />
            </template>
          </IconToolbar>

          <UModal
            v-model="deleteConfirmationShowing"
            :ui="{ container: 'items-start md:items-center' }"
          >
            <ConfirmModal
              title="Delete Lists"
              @cancel="deleteConfirmationShowing = false"
              @confirm="deleteLists"
              @close="deleteConfirmationShowing = false"
            >
              <p>Are you sure?</p>
            </ConfirmModal>
          </UModal>
        </div>
      </div>

      <!-- TODO: This needs to be it's own custom component. This is more of a placeholder right now -->
      <!-- <div class="menu-searchbar"><SearchBar /></div> -->

      <div class="menu-nav">
        <div class="menu-sections">
          <MediaLibrarySidebarRow
            v-if="globalLists"
            v-for="list in globalLists.items"
            :auto-list="list.autoList"
            :current-selection-key="currentSelectionKey"
            :list-key="list.key"
            :label="list.label"
            :image-count="list.imageCount"
            :video-count="list.videoCount"
            :preview-images="list.previewImages"
            :show-selector="inSelectionMode || internalSelectionMode"
            @click="selectList(list)"
          />
        </div>

        <div class="menu-lists">
          <p class="menu-sub-header">Lists</p>
          <MediaLibrarySidebarRow
            v-if="tagLists"
            v-for="(list, index) in tagLists.items"
            :key="index"
            :current-selection-key="currentSelectionKey"
            :list-key="list.key"
            :label="list.label"
            :image-count="list.imageCount"
            :video-count="list.videoCount"
            :preview-images="list.previewImages"
            :selected="isSelected(list)"
            :show-selector="inSelectionMode || internalSelectionMode"
            @click="selectList(list)"
          />
        </div>

        <!--
        <p class="uppercase text-xs text-gray-600 px-3">Favorites</p>

        <div class="flex flex-col py-4">
          <MediaSidebarCreatorRow
            v-for="index in 30"
            :key="index"
            @click="$emit('selected', index)"
          />
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script>
  export default { name: "MediaLibrarySidebar" }
</script>

<script setup>
  import { useUserStore } from "@/store/user"
  const route = useRoute()

  defineProps({
    currentSelectionKey: {
      type: String,
      default: null,
    },

    globalLists: {
      type: Object,
      required: true,
    },

    restriction: {
      type: String,
      default: null,
    },

    tagLists: {
      type: Object,
      required: true,
    },
  })

  const emit = defineEmits([
    "navigate-back",
    "sort-filter-changed",
    "selected",
    "tags-deleted",
    "close-modal",
  ])

  const userStore = useUserStore()
  const isMobile = useMediaQuery("(max-width: 767px)")

  const listSortSelectedOption = ref("newest")
  const listSortOptions = [
    {
      value: "name",
      label: "Name",
    },
    {
      value: "newest",
      label: "Recent",
    },
    {
      value: "oldest",
      label: "Oldest",
    },
  ]

  watch(listSortSelectedOption, (newValue) => {
    switch (newValue) {
      case "name":
        emit("sort-filter-changed", { field: "tag", order: "asc" })
        break
      case "newest":
        emit("sort-filter-changed", { field: "created_at", order: "desc" })
        break
      case "oldest":
        emit("sort-filter-changed", { field: "created_at", order: "asc" })
        break
      default:
    }
  })

  const inSelectionMode = ref(false)
  const toolbarTools = [
    {
      key: "filter",
      label: "Filter",
      icon: "fa-light fa-bars-filter",
    },
  ]

  const internalSelectionMode = ref(false)
  const internalSelectedLists = ref([])

  const selectList = (list) => {
    if (internalSelectionMode.value) {
      if (internalSelectedLists.value.includes(list)) {
        internalSelectedLists.value = internalSelectedLists.value.filter(
          (l) => l.key !== list.key,
        )
      } else {
        internalSelectedLists.value = [...internalSelectedLists.value, list]
      }
    } else {
      emit("selected", list)
    }
  }

  const isSelected = (list) => {
    return internalSelectedLists.value.map((l) => l.key).includes(list.key)
  }

  const deleteConfirmationShowing = ref(false)
  const listOptionToolbarTools = computed(() => {
    if (internalSelectionMode.value) {
      return [
        {
          icon: "fa-light fa-trash",
          label: "Delete",
          click: () => {
            deleteConfirmationShowing.value = true
          },
        },
      ]
    } else {
      return [
        {
          icon: "fa-light fa-circle-check",
          label: "Select",
          click: () => {
            internalSelectionMode.value = true
          },
        },
      ]
    }
  })

  const config = useRuntimeConfig()
  const toast = useToast()
  const deleteLists = () => {
    try {
      $api(`${config.public.API_URL}/api/media/tags`, {
        method: "DELETE",
        body: {
          tags: internalSelectedLists.value.map((l) => l.key),
        },
      })
    } catch (error) {
      console.log(error)
    }

    emit("tags-deleted")

    toast.add({
      title:
        internalSelectedLists.value.length > 1
          ? "Lists were deleted."
          : "List was deleted.",
      color: "green",
    })

    internalSelectedLists.value = []
    internalSelectionMode.value = false
    deleteConfirmationShowing.value = false
  }

  const handleSwipe = (event) => {
    switch (event) {
      case "top":
        break
      case "bottom":
        emit("close-modal")
        break
      case "left":
        break
      case "right":
        break
      default:
        break
    }
  }
</script>

<style lang="scss" scoped>
  .media-library-sidebar {
    @apply w-full h-full flex flex-col gap-2 justify-start items-start;
  }
  .menu-searchbar {
    @apply w-full px-3;
  }
  .breadcrumb-bar {
    @apply w-full;
  }
  .menu-container {
    @apply relative w-full flex flex-col items-center justify-between gap-2 px-2;
    @apply bg-white dark:bg-[#1B2022] rounded-xl p-2;
    @apply overflow-y-auto;
  }
  .desktop-header {
    @apply w-full flex flex-row justify-start items-center gap-2;
  }
  .menu-nav {
    @apply w-full flex flex-col;
  }
  .menu-sections {
    @apply flex flex-col py-4 gap-1.5;
  }
  .menu-lists {
    @apply flex flex-col py-2 gap-1.5;
  }
  .menu-sub-header {
    @apply uppercase text-xs text-gray-600 px-3;
  }
</style>
