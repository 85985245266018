<template>
  <UModal v-model="showModal" :ui="modalStyles">
    <Modal :title="title" @close="$emit('close')">
      <ProfileProgressBar :showItems="true" class="animate-fade-down" />
      <div class="flex flex-col gap-1 px-4 py-2">
        <Button
          block
          size="xl"
          class="text-lg"
          @click="navigateTo('/settings/profile')"
        >
          {{ buttonText }}
        </Button>
      </div>
    </Modal>
  </UModal>
</template>

<script setup>
  const props = defineProps({
    buttonText: {
      type: String,
      default: "Continue",
    },

    open: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      required: true,
    },
  })

  const showModal = ref(props.open)

  watch(
    () => props.open,
    (value) => {
      showModal.value = value
    },
  )

  const modalStyles = {
    container: "items-start md:items-center !w-screen",
    background: "dark:bg-[#1b2022]",
    overlay: {
      background: "dark:bg-[#1b2022] dark:bg-opacity-85",
    },
    fullscreen: "w-screen h-auto",
  }
</script>
