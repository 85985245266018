<template>
  <ClientOnly>
    <div class="split-view">
      <div class="split-view-left" :class="navigationViewable">
        <slot name="left" />
      </div>

      <div class="split-view-right" :class="contentViewable">
        <slot name="right" />
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
  const props = defineProps({
    showSidebar: {
      type: Boolean,
      default: true,
    },
  })

  const isDesktop = useMediaQuery("(min-width: 768px)")
  const isMobile = useMediaQuery("(max-width: 767px)")

  const navigationViewable = computed(() => {
    if (isDesktop.value || (isMobile.value && props.showSidebar)) {
      return "show"
    } else {
      return "hide"
    }
  })

  const contentViewable = computed(() => {
    if (isDesktop.value || (isMobile.value && !props.showSidebar)) {
      return "show"
    } else {
      return "hide"
    }
  })
</script>

<style lang="scss" scoped>
  .split-view {
    @apply w-full h-full flex flex-row items-stretch items-start gap-1.5 sm:px-0 pb-2;
  }
  .split-view-left {
    @apply relative overflow-y-hidden w-full md:w-[400px];
  }
  .split-view-right {
    @apply relative w-full overflow-y-hidden sm:flex;
  }
  .show {
    @apply flex;
  }
  .hide {
    @apply hidden;
  }
</style>
