<template>
  <Button
    v-if="isFollowing"
    :size="size"
    :block="block"
    @click="handleUnfollow"
  >
    <font-awesome-icon
      v-if="!!icon"
      icon="fa-solid fa-user-minus"
      :class="iconClass"
    />
    {{ "Un" + props.label.toLowerCase() }}
  </Button>

  <Button
    v-else
    theme="primary"
    :size="size"
    :block="block"
    @click="handleFollow"
  >
    <font-awesome-icon v-if="!!icon" :icon="icon" :class="iconClass" />
    {{ props.label }}
  </Button>
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const loginShowing = inject("loginModalOpen")

  const userStore = useUserStore()
  const runtimeConfig = useRuntimeConfig()
  const isFollowing = ref(false)

  const props = defineProps({
    label: { type: String, default: "Follow" },
    iconOnly: { type: Boolean, default: false },
    icon: { type: String, default: null },
    iconClass: { type: String, default: null },
    size: { type: String, default: "md" },
    variant: { type: String, default: "primary" },
    following: { type: Object, required: true },
    block: { type: Boolean, default: false },
  })

  const emit = defineEmits(["creator-followed", "creator-unfollowed"])

  const getFollowStatus = async () => {
    if (userStore.loggedIn) {
      await useAuthFetch(
        `${runtimeConfig.public.API_URL}/api/follows/${props.following.username}`,
        {
          method: "GET",
          onResponse: ({ response }) => {
            if (response.ok) {
              isFollowing.value = true
            } else {
              isFollowing.value = false
            }
          },
        },
      )
    }
  }

  const handleFollow = async (e) => {
    e.preventDefault()
    console.log("---> following", props.following.username)

    if (userStore.loggedIn) {
      const { pending, data } = await useAuthFetch(
        runtimeConfig.public.API_URL +
          `/api/follows/${props.following.username}`,
        { method: "POST" },
      )

      isFollowing.value = true

      // Update Suggestions List
      emit("creator-followed", props.following)
    } else {
      loginShowing.value = true
    }
  }

  const handleUnfollow = async (e) => {
    e.preventDefault()
    console.log("---> unfollow", props.following.username)

    if (userStore.loggedIn) {
      const { pending, data } = await useAuthFetch(
        runtimeConfig.public.API_URL +
          `/api/follows/${props.following.username}`,
        { method: "DELETE" },
      )

      isFollowing.value = false

      // Update Suggestions List
      emit("creator-unfollowed", props.following)
    } else {
      loginShowing.value = true
    }
  }

  await getFollowStatus()
</script>
