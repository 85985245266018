<template>
  <ClientOnly>
    <div class="flex flex-col gap-4">
      <CreditCardForm mode="create" @new-card="handleNewCard" />
    </div>
  </ClientOnly>
</template>

<script setup>
  const purchaseForm = inject("form")
  const purchaseSubscription = inject("purchaseSubscription")

  const props = defineProps({
    nextStep: {
      type: String,
      required: false,
      default: "select-token-amount",
    },
    initialStep: {
      type: String,
      required: true,
    },
  })

  const emit = defineEmits(["moveToStep", "refresh-payment-accounts"])

  const handleNewCard = async (card) => {
    if (card) {
      purchaseForm.data.paymentMethodSelected = { id: card.id }
      if (purchaseForm.data.agreeToTerms) {
        await purchaseSubscription()
      } else {
        emit("move-to-step", { step: props.initialStep })
        emit("refresh-payment-accounts")
      }
    }
  }
</script>
