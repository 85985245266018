<template>
  <div class="flex flex-col gap-4">
    <div
      @click="updateCard(i)"
      class="flex flex-row items-center justify-between p-4 rounded-lg bg-cool-200 dark:bg-[#2B3236]"
    >
      <div class="flex flex-row items-center gap-4">
        <img width="35" height="24" src="/images/payment-visa-badge.png" />
        <span>
          **** **** ****
          <span class="font-bold">8172</span>
        </span>
      </div>

      <span class="font-mono">8/28</span>
    </div>

    <Checkbox v-model="primary" type="checkbox">
      Set this card as Primary
    </Checkbox>

    <UDivider />

    <div class="w-full flex flex-row items-center justify-between gap-1.5">
      <Button theme="primary" class="w-full">Delete</Button>
      <Button
        theme="primary"
        :disabled="!valid"
        size="lg"
        @click="addNewPaymentAccount"
        :block="true"
      >
        <span v-if="formPending">Processing...</span>
        <span v-else>Update</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
  const primary = ref(true)
</script>
