<template>
  <div class="w-full flex flex-col gap-4">
    <div v-if="pending" v-for="n in 4" :key="n">
      <div class="flex items-center space-x-4">
        <USkeleton class="w-12 h-12 rounded-full" />
        <div class="space-y-2">
          <USkeleton class="h-4 w-[250px]" />
          <USkeleton class="h-4 w-[200px]" />
        </div>
      </div>
    </div>

    <TabbedPanels
      class="mt-4"
      initial-tab="users"
      tab-spacing="gap-12"
      :panels="panels"
      :panelWrapped="false"
      @switch-tab="currentSection = $event"
    >
      <template #users>
        <SearchResultsUserResults
          v-if="!pending && currentSection == 'users'"
          :users="users"
          :page-number="userPageNumber"
          :last-page="lastUserPage"
        />
      </template>
      <template #posts>
        <SearchResultsPostResults
          v-if="!pending && currentSection == 'posts'"
          :page-number="postPageNumber"
          :last-page="lastPostPage"
          :posts="posts"
          @load-more="loadMorePosts"
        />
      </template>
    </TabbedPanels>
  </div>
</template>

<script setup>
  const props = defineProps({
    query: String,
  })

  const config = useRuntimeConfig()
  const currentSection = ref("users")
  const emit = defineEmits(["loadMore"])
  const pending = ref(false)

  const lastUserPage = ref(1)
  const userPageNumber = ref(1)
  const userSearchCount = ref(0)
  const users = ref([])

  const lastPostPage = ref(1)
  const postPageNumber = ref(1)
  const postSearchCount = ref(0)
  const posts = ref([])

  const panels = [
    {
      key: "users",
      chipText: userSearchCount,
      chipSize: "2xl",
      label: "Users",
    },
    {
      key: "posts",
      chipText: postSearchCount,
      chipSize: "2xl",
      label: "Posts",
    },
  ]

  const fetchData = async () => {
    pending.value = true

    const data = await $api(config.public.API_URL + `/api/search`, {
      method: "GET",
      query: {
        query: props.query,
      },
    })

    lastUserPage.value = data.users.pagination.totalPages
    userSearchCount.value = data.users.pagination.totalItems
    users.value = data.users.items

    lastPostPage.value = data.posts.pagination.totalPages
    postSearchCount.value = data.posts.pagination.totalItems
    posts.value = data.posts.items

    pending.value = false
  }

  watch(() => props.query, fetchData, { immediate: true })

  const loadMoreUsers = async () => {
    if (userPageNumber.value + 1 > lastUserPage.value) return

    userPageNumber.value = userPageNumber.value + 1

    const data = await $api(config.public.API_URL + `/api/search`, {
      method: "GET",
      query: {
        query: props.query,
        page: userPageNumber.value,
        section: currentSection.value,
      },
    })

    lastUserPage.value = data.users.pagination.totalPages
    userSearchCount.value = data.users.pagination.totalItems
    users.value = [...users.value, ...data.users.items]
  }

  const loadMorePosts = async () => {
    if (postPageNumber.value + 1 > lastPostPage.value) return

    postPageNumber.value = postPageNumber.value + 1

    const data = await $api(config.public.API_URL + `/api/search`, {
      method: "GET",
      query: {
        query: props.query,
        page: postPageNumber.value,
        section: currentSection.value,
      },
    })

    lastPostPage.value = data.posts.pagination.totalPages
    postSearchCount.value = data.posts.pagination.totalItems
    posts.value = [...posts.value, ...data.posts.items]
  }
</script>
