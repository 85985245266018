<template>
  <div
    v-if="itemsSelected"
    class="w-full rounded-xl bg-white p-1 dark:bg-[#1b2022] animate-fade-up"
  >
    <div
      class="flex flex-col rounded-xl px-4 py-3 gap-4 sm:flex-row sm:items-center sm:justify-between"
    >
      <div class="flex flex-row items-center justify-between gap-2">
        <div class="flex flex-row items-center gap-2">
          <span class="font-bold text-sm sm:text-lg">
            {{ numberOfItemsSelected }} Selected
          </span>
          <!--
        <font-awesome-icon
          icon="fa-solid fa-xmark"
          class="rounded-full p-2 aspect-t transition transition-colors bg-cool-100 dark:bg-[#2B3236]"
          @click="$emit('clear-selected')"
        />
          -->
          <Button
            theme="tertiary"
            shape="rounded"
            :iconOnly="true"
            @click="$emit('clear-selected')"
          >
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </Button>
        </div>

        <div class="flex sm:hidden flex-row gap-2">
          <Button
            v-if="allowMultiSelect"
            theme="outline"
            :iconOnly="true"
            @click="$emit('delete-selected')"
            title="Delete Selected"
          >
            <font-awesome-icon icon="fa-light fa-trash" />
          </Button>
          <Button
            v-if="allowMultiSelect"
            theme="outline"
            :iconOnly="true"
            @click="$emit('add-to-list')"
            title="Add to List"
          >
            <font-awesome-icon icon="fa-light fa-square-right" />
          </Button>
        </div>
      </div>

      <div class="flex flex-col sm:flex-row items-center justify-between gap-2">
        <div class="hidden sm:flex flex-row gap-2">
          <Button
            v-if="allowMultiSelect"
            theme="outline"
            :iconOnly="true"
            @click="$emit('delete-selected')"
            title="Delete Selected"
          >
            <font-awesome-icon icon="fa-light fa-trash" />
          </Button>
          <Button
            v-if="allowMultiSelect"
            theme="outline"
            :iconOnly="true"
            @click="$emit('add-to-list')"
            title="Add to List"
          >
            <font-awesome-icon icon="fa-light fa-square-right" />
          </Button>
        </div>
        <div class="w-full flex flex-row items-center justify-between gap-2">
          <Button
            v-if="showAddToMessageButton"
            theme="primary"
            @click="$emit('attach-selected-media', 'message')"
            class="px-4 sm:px-6"
          >
            <font-awesome-icon icon="fa-light fa-message-plus" />
            Add to Message
          </Button>
          <Button
            v-if="showAddToPostButton"
            theme="primary"
            @click="$emit('attach-selected-media', 'post')"
            class="px-4 sm:px-6"
          >
            <font-awesome-icon icon="fa-light fa-square-plus" />
            Add to Post
          </Button>
          <Button
            v-if="showSetProfilePhotoButton"
            theme="primary"
            @click="$emit('attach-selected-media', 'avatar')"
          >
            <font-awesome-icon icon="fa-light fa-square-plus" />
            Set profile photo
          </Button>
          <Button
            v-if="showSetProfileBackgroundButton"
            theme="primary"
            @click="$emit('attach-selected-media', 'background')"
          >
            <font-awesome-icon icon="fa-light fa-square-plus" />
            Set profile background
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    allowMultiSelect: {
      type: Boolean,
      default: true,
    },

    numberOfItemsSelected: {
      type: Number,
      default: 0,
    },

    showAddToMessageButton: {
      type: Boolean,
      default: true,
    },

    showAddToPostButton: {
      type: Boolean,
      default: true,
    },

    showSetProfilePhotoButton: {
      type: Boolean,
      default: true,
    },

    showSetProfileBackgroundButton: {
      type: Boolean,
      default: false,
    },
  })

  const toolbarTools = [
    {
      key: "sort",
      label: "Sort",
      icon: "fa-light fa-bars-filter",
    },
    {
      key: "search",
      label: "Search",
      icon: "fa-light fa-magnifying-glass",
      click: () => (searchModalOpen.value = true),
    },
    {
      key: "more",
      label: "More",
      icon: "fa-light fa-ellipsis",
    },
  ]

  const searchModalOpen = ref(false)
  const itemsSelected = computed(() => {
    return props.numberOfItemsSelected > 0
  })
</script>
