<template>
  <div class="tabbed-panels">
    <div class="tabs no-scrollbar" :class="tabSpacing">
      <div
        v-for="panel in panels"
        :key="panel.key"
        @click="switchTab(panel.key)"
        class="flex flex-col items-center gap-3 cursor-pointer"
      >
        <span
          class="uppercase text-[#595858] text-xs md:text-sm whitespace-nowrap"
          :class="{
            highlight: panel.key == currentTab,
          }"
        >
          <div v-if="panel.chipText" class="flex flex-row items-center gap-1">
            <span class="px-2">{{ panel.label }}</span>
            <div
              class="rounded-full py-1 px-1 text-xs font-medium content-[''] leading-none grid place-items-center min-w-[24px] min-h-[24px] bg-gray-500"
            >
              {{ panel.chipText }}
            </div>
          </div>
          <span v-else class="px-2">{{ panel.label }}</span>
        </span>
        <div
          v-if="panel.key == currentTab"
          class="w-[33%] border border-pink-600"
        />
      </div>
    </div>

    <div
      v-if="panelWrapped"
      class="h-full flex flex-col self-stretch overflow-y-auto no-scrollbar"
    >
      <Panel class="h-full">
        <slot :name="currentTab" />
      </Panel>
    </div>
    <div
      v-else
      class="h-full flex flex-col self-stretch overflow-y-auto no-scrollbar"
    >
      <slot :name="currentTab" />
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    initialTab: {
      type: String,
      required: true,
    },

    panels: {
      type: Array,
      required: true,
    },

    panelWrapped: {
      type: Boolean,
      default: true,
    },

    tabSpacing: {
      type: String,
      default: "justify-start",
    },
  })

  const emit = defineEmits(["switchTab"])
  const currentTab = ref(props.initialTab)

  const switchTab = (tab) => {
    currentTab.value = tab
    emit("switchTab", tab)
  }

  watch(
    () => props.initialTab,
    (newVal) => {
      switchTab(newVal)
    },
  )
</script>

<style lang="scss" scoped>
  .tabbed-panels {
    @apply h-full flex flex-col items-start overflow-hidden;
  }

  .tabs {
    @apply w-full flex flex-row items-start gap-3 pb-[3px] overflow-y-hidden overflow-x-auto;
  }

  .highlight {
    @apply font-semibold dark:text-white;
  }
</style>
