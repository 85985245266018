<template>
  <canvas ref="canvas"></canvas>
</template>

<script setup>
  import { ref, watch } from "vue"
  import { decodeBlurHash } from "fast-blurhash"

  const props = defineProps({
    blurHash: String,
    blurWidth: Number,
    blurHeight: Number,
    blurClass: String,
  })

  const firstRender = ref(true)

  const tryDecodeBlurHash = (blurHash, width, height) => {
    if (!blurHash) {
      const samples = [
        "LEHLk~WB2yk8pyo0adR*.7kCMdnj",
        "LGF5]+Yk^6#M@-5c,1J5@[or[Q6.",
        "L6PZfSi_.AyE_3t7t7R**0o#DgR4",
        "LKN]Rv%2Tw=w]~RBVZRi};RPxuwH",
        "LDLW*CqZ010z~WrBrFSJ.AnyIT_3",
      ]
      blurHash = samples[Math.floor(Math.random() * samples.length)]
    }

    try {
      return decodeBlurHash(blurHash, width, height)
    } catch (err) {
      decodeBlurHash(
        samples[Math.floor(Math.random() * samples.length)],
        width,
        height,
      )
    }
  }

  const resizeCanvas = (dimensionsFromProps) => {
    dimensionsFromProps = dimensionsFromProps === true
    if (canvas.value == null) return

    const container = canvas.value.parentElement
    canvas.value.width = dimensionsFromProps
      ? props.blurWidth || container.clientWidth
      : container.clientWidth
    canvas.value.height = dimensionsFromProps
      ? props.blurHeight || container.clientHeight
      : container.clientHeight

    const ctx = canvas.value.getContext("2d")
    const pixels = tryDecodeBlurHash(
      props.blurHash,
      canvas.value.width,
      canvas.value.height,
    )

    const imageData = new ImageData(
      pixels,
      canvas.value.width,
      canvas.value.height,
    )
    ctx.putImageData(imageData, 0, 0)
  }

  const handleVisibilityChange = (isVisible) => {
    if (isVisible) {
      resizeCanvas(firstRender.value)
      firstRender.value = false
      const resizeObserver = new ResizeObserver(resizeCanvas)
      resizeObserver.observe(canvas.value.parentElement)
      window.addEventListener("resize", resizeCanvas)
    }
  }

  const canvas = ref(null)
  const canvasIsVisible = useElementVisibility(canvas)

  watch(canvasIsVisible, handleVisibilityChange)
</script>

<style scoped>
  .hidden {
    display: none;
  }
</style>
