<template>
  <div
    class="sidebar-row group"
    :class="{
      'bg-cool-200 dark:bg-[#2B3236]': currentSelectionKey == listKey,
      rejected: listKey == 'rejected',
    }"
  >
    <div class="w-[55%] flex flex-row items-center gap-3 truncate">
      <input
        type="radio"
        v-if="!autoList && showSelector"
        :checked="selected"
      />
      <font-awesome-icon
        v-if="autoList"
        class="icon dark:text-[#F2699B] fa-rotate-180"
        :class="iconClasses"
        :icon="icon"
      />
      <div class="w-full flex flex-col">
        <p class="w-full font-semibold text-sm truncate">
          {{ label }}
        </p>
        <MediaPackSummary
          :image-count="imageCount"
          :video-count="videoCount"
          :free="true"
          :text="false"
        />
      </div>
    </div>

    <MediaPreviewPhotoLineup :preview-images="previewImages" />
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    autoList: {
      type: Boolean,
      default: false,
    },

    currentSelectionKey: {
      type: String,
      default: null,
    },

    imageCount: {
      type: Number,
      default: 0,
    },

    label: {
      type: String,
      required: true,
    },

    listKey: {
      type: String,
      required: true,
    },

    previewImages: {
      type: Array,
      default: [],
    },

    selected: {
      type: Boolean,
      default: false,
    },

    showSelector: {
      type: Boolean,
      default: false,
    },

    videoCount: {
      type: Number,
      default: 0,
    },
  })

  const icon = computed(() => {
    if (props.listKey == "processing") return "fa-light fa-spinner"
    if (props.listKey == "rejected") return "fa-light fa-ban"
    return "fa-light fa-sparkles"
  })

  const iconClasses = computed(() => {
    if (props.listKey == "processing") return "animate-spin"
  })
</script>

<style lang="scss" scoped>
  .sidebar-row {
    @apply flex flex-row items-center justify-between gap-1 py-2 px-3 rounded-lg cursor-pointer transition-colors hover:bg-cool-200 hover:dark:bg-[#2B3236];
    @apply text-sm;
  }
  .rejected {
    .icon,
    span {
      @apply text-red-400;
    }
  }
</style>
