import { useUserStore } from "@/store/user"

class FileUploaderItem {
  constructor(options) {
    this.fileType = "unknown"
    this.totalChunks = 0
    this.totalChunksUploaded = 0
    this.initialChunk = null
    this.chunks = []
    this.id = this.randomId()
    this.previewUrl = options.previewUrl
    this.userId = options.userId
    this.profileUrl = options.profileUrl
    this.uploadedFile = options.uploadedFile
    this.maxUploadSizeInMegabytes = options.maxUploadSizeInMegabytes
    this.progressCallback = options.progressCallback
    this.signatureCallback = options.signatureCallback
    this.completeCallback = options.completeCallback
    this.errorCallback = options.errorCallback
    this.location = options.location
    this.initialize()
  }

  initialize() {}

  progress() {
    if (this.totalChunksUploaded == 0) return 0
    return Math.round((this.chunkIndex() / this.totalChunks) * 100)
  }

  startUpload(signature, date) {
    this.createChunks()
    this.totalChunks = this.chunks.length
    this.initialChunkUrl = URL.createObjectURL(this.chunks[0].valueOf())
    this.upload(this.chunks.shift())
  }

  createChunks() {
    let size = 1000 * 1024 * 5,
      chunks = Math.ceil(this.uploadedFile.size / size)

    for (let i = 0; i < chunks; i++) {
      this.chunks.push(
        this.uploadedFile.slice(
          i * size,
          Math.min(i * size + size, this.uploadedFile.size),
          this.uploadedFile.type,
        ),
      )
    }
  }

  async upload(chunk) {
    const runtimeConfig = useRuntimeConfig() // Assuming this is defined somewhere
    const date = new Date().toUTCString()
    const payload = `${date}:${this.location.origin}${this.profileUrl}:${this.userId}:${this.id}:${this.totalChunks}:${this.chunkIndex()}`
    const signature = await this.signatureCallback(payload)
    const totalChunks = this.totalChunks
    const chunkIndex = this.chunkIndex()
    const id = this.id
    const userId = this.userId
    const location = this.location
    const profileUrl = this.profileUrl

    let self = this

    try {
      const response = await $api(runtimeConfig.public.FILE_UPLOAD_URL, {
        method: "POST",
        headers: {
          "X-Chunk-Count": totalChunks,
          "X-Chunk-ID": chunkIndex,
          "X-Identifier": id,
          "X-User-ID": userId,
          "X-User-Profile": `${location.origin}${profileUrl}`,
          "X-Timestamp": date,
          "X-Signature": signature,
        },
        body: this.formData(chunk),
      })

      if (self.chunks.length === 0) {
        self.id = response.id
        self.path = response.path
        self.complete = true
        self.totalChunksUploaded = self.totalChunks
        self.progressCallback(self)
        self.completeCallback(self)
      } else {
        self.totalChunksUploaded++
        self.progressCallback(self)
        self.upload(self.chunks.shift())
      }
    } catch (error) {
      console.log(`ERROR: ${error}`)
      let result = error.response || {}
      let data = result.data || {}
      let errors = data.errors || "Upload failed"
      this.errorCallback(errors)
    }
  }

  randomId() {
    return [...Array(32)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("")
  }

  formData(chunk) {
    let formData = new FormData()

    formData.set("identifier", this.id)
    formData.set("file_name", this.uploadedFile.name)
    formData.set("file", chunk, `${this.uploadedFile.name}.part`)

    return formData
  }

  chunkIndex() {
    return this.totalChunks - this.chunks.length
  }

  type() {
    if (this.uploadedFile.type.match(/^image\//)) {
      return "image"
    } else if (this.uploadedFile.type.match(/^video\//)) {
      return "video"
    } else if (this.uploadedFile.type.match(/^application\/pdf/)) {
      return "pdf"
    }
  }
}

export default FileUploaderItem
