<template>
  <div class="search-results-users-container scrollable no-scrollbar">
    <div v-if="users.length" class="flex flex-col gap-2">
      <Panel v-for="user in users" :key="user.id">
        <div class="flex flex-row items-center justify-between gap-2">
          <IdentityCard :user="user" />
          <FollowButton
            :following="user"
            @creator-followed="$emit('follow', user)"
          />
        </div>
      </Panel>

      <div v-element-visibility="loadMore">
        <div class="h-4" />
        <div v-if="thisIsTheEnd" class="flex justify-center my-12">
          <Logo variant="square" size="h-16" />
        </div>
      </div>
    </div>

    <div v-else class="flex flex-col justify-center items-center gap-12">
      <p class="text-lg text-gray-500 my-12">No users found</p>
      <Logo variant="square" size="h-16" />
    </div>
  </div>
</template>

<script setup>
  import { vElementVisibility } from "@vueuse/components"

  const props = defineProps({
    lastPage: Number,
    pageNumber: Number,
    users: Array,
  })

  const thisIsTheEnd = computed(() => props.pageNumber === props.lastPage)
  const emit = defineEmits(["loadMore"])

  const loadMore = () => {
    emit("loadMore")
  }
</script>

<style lang="scss" scoped>
  .search-results-users-container {
    @apply w-full;
  }
  .scrollable {
    @apply h-[calc(100vh-188.5px)] overflow-y-auto;
  }
</style>
