<template>
  <div class="h-full w-full flex flex-col items-stretch gap-1.5">
    <MediaLibraryHeader
      v-if="media"
      :inModal="inModal"
      :label="label"
      :image-count="imageCount"
      :video-count="videoCount"
      :sorting-order="sortingOrder"
      :items-selected="itemSelectionList"
      @close-modal="$emit('close-modal')"
      @tag-selected="tagSelected"
      @sorting-order-change="emit('sorting-order-change', $event)"
      @navigate-back="$emit('navigate-back')"
    />
    <div
      v-if="media"
      class="h-full rounded-xl bg-white dark:bg-[#1b2022] overflow-auto no-scrollbar"
    >
      <div class="w-full flex flex-col justify-between mb-4">
        <div
          class="w-full flex justify-center p-4 overflow-y-scroll no-scrollbar"
        >
          <div v-if="media" class="w-full flex flex-col gap-6">
            <div class="w-full flex flex-row items-center justify-between">
              <div class="flex flex-row items-center gap-1">
                <Button
                  v-if="allowedMediaType === 'all'"
                  :theme="mediaFilterType == 'all' ? 'primary' : 'outline'"
                  @click="$emit('change-filter-type', 'all')"
                  size="sm"
                  class="text-xs sm:text-sm"
                >
                  All
                </Button>
                <Button
                  v-if="['all', 'video'].includes(allowedMediaType)"
                  :theme="mediaFilterType == 'video' ? 'primary' : 'outline'"
                  @click="$emit('change-filter-type', 'video')"
                  size="sm"
                  class="text-xs sm:text-sm"
                >
                  Videos
                </Button>
                <Button
                  v-if="['all', 'image'].includes(allowedMediaType)"
                  :theme="mediaFilterType == 'image' ? 'primary' : 'outline'"
                  @click="$emit('change-filter-type', 'image')"
                  size="sm"
                  class="text-xs sm:text-sm"
                >
                  {{
                    [restriction?.toUpperCase(), "Images"]
                      .filter(Boolean)
                      .join(" ")
                  }}
                </Button>
              </div>
              <FileUploader
                class="text-xs sm:text-sm animate-fade"
                size="sm"
                v-if="
                  showUploadButton &&
                  allowCreationInList &&
                  !itemSelectionList.length
                "
                :multiple="true"
                :maxUploadSizeInMegabytes="5"
                :accepts="mimeTypes"
                :creationDate="new Date().toJSON()"
                :initial-tags="listInitialTags"
                @upload-complete="uploadComplete"
              />

              <div
                v-if="itemSelectionList.length"
                class="sm:hidden md:flex gap-1.5 md:animate-fade"
              >
                <Button theme="outline" size="sm" @click="tagSelected">
                  <font-awesome-icon icon="fa-light fa-pencil" />
                  Edit Tags
                </Button>
                <!-- TODO: Determine if bulk deleting uploaded media should be allowed? -->
                <!-- <Button theme="primary" size="sm" @click="showDeleteConfirmation">
                  <font-awesome-icon icon="fa-light fa-trash" />
                  Delete
                </Button> -->
              </div>
            </div>

            <div class="flex flex-col gap-3">
              <!-- <span>Today, February 28</span> -->
              <div class="grid grid-cols-3 gap-4 sm:grid-cols-4 lg:grid-cols-6">
                <MediaLibraryThumb
                  v-for="m in media"
                  :media="m"
                  :selected="isSelected(m)"
                  @toggle-selection="toggleSelection(m)"
                  @edit="editSingleMedia(m)"
                  @preview="handleMediaPreview(m)"
                  @update-medium="emit('update-medium', $event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Panel v-else class="h-full flex items-center justify-center">
      <div
        v-if="showUploadButton && userStore.approvedCreator"
        class="h-full flex flex-col items-center justify-center gap-3 text-center text-lg text-gray-600"
      >
        <span>Upload new content or add existing media.</span>
        <div class="w-min">
          <FileUploader
            v-if="allowCreationInList"
            :multiple="true"
            :maxUploadSizeInMegabytes="5"
            :accepts="mimeTypes"
            :creationDate="new Date().toJSON()"
            :initial-tags="listInitialTags"
            @upload-complete="uploadComplete"
          />
        </div>
      </div>
      <div v-else class="text-center text-lg text-gray-600">
        Select a list or favorite
        <br />
        to view content.
      </div>
    </Panel>

    <MediaSelectionViewManager
      :allow-multi-select="allowMultiSelect"
      :number-of-items-selected="numberOfItemsSelected"
      :show-add-to-message-button="showAddToMessageButton"
      :show-add-to-post-button="showAddToPostButton"
      :show-set-profile-photo-button="showSetProfilePhotoButton"
      :show-set-profile-background-button="showSetProfileBackgroundButton"
      @clear-selected="clearSelected"
      @delete-selected="showDeleteConfirmation"
      @add-to-list="addToListModalShowing = true"
      @attach-selected-media="attachSelectedMedia"
    />
  </div>

  <!-- Modal: Delete Confirm -->
  <UModal
    v-model="deleteMediaConfirmationShowing"
    :ui="{ container: 'items-start md:items-center' }"
  >
    <ConfirmModal
      title="Delete"
      @cancel="deleteMediaConfirmationShowing = false"
      @confirm="deleteSelected"
      @close="deleteMediaConfirmationShowing = false"
    >
      <div class="flex flex-col gap-2">
        <MediaPreviewPhotoLineup
          :preview-images="selectionPreviewThumbnails"
          class="ml-1"
        />
        <span>
          Are you sure want to delete
          {{ numberOfItemsSelected > 1 ? "these" : "this" }}
          {{ $numberToHuman(numberOfItemsSelected) }}?
        </span>
      </div>
    </ConfirmModal>
  </UModal>

  <!-- Modal: Edit Media Tags -->
  <UModal
    v-model="tagMediaModalOpen"
    :ui="{ container: 'items-start md:items-center' }"
  >
    <Modal title="Edit Tags" @close="tagMediaModalOpen = false">
      <FormGroup label="Tags" hint="Separate tags with commas">
        <Input
          ref="tagsInput"
          v-model="newTags"
          placeholder="tags"
          :autofocus="false"
        />
      </FormGroup>

      <div class="w-full flex flex-row flex-wrap gap-1.5">
        <div v-for="tag in tagsToSubmit" :key="tag">
          <div
            class="p-2 pl-3 bg-gray-200 dark:bg-gray-800 rounded-full flex items-center gap-1"
          >
            <span>{{ tag }}</span>
            <font-awesome-icon
              @click="removeTagToSubmit(tag)"
              icon="fa-solid fa-xmark"
              class="rounded-full p-1 bg-gray-300 dark:bg-gray-900 hover:bg-primary-600 hover:bg-primary-600 hover:text-white transition-colors cursor-pointer"
              style="aspect-ratio: 1/1"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-row items-center justify-end gap-1.5">
        <Button theme="outline" @click="tagMediaModalOpen = false">
          Cancel
        </Button>
        <Button @click="updateMedia">Save</Button>
      </div>
    </Modal>
  </UModal>

  <!-- Modal: Add to TagList  -->
  <UModal
    v-model="addToListModalShowing"
    :ui="{ container: 'items-start md:items-center' }"
  >
    <MediaLibraryAddToListModal
      :tag-lists="tagLists"
      :media="itemSelectionList"
      @close="addToListModalShowing = false"
    />
  </UModal>
</template>

<script>
  export default { name: "MediaLibraryBrowser" }
</script>

<script setup>
  import { useUserStore } from "@/store/user"
  import { MediaModal } from "#components"

  const route = useRoute()
  const modal = useModal()
  const areaName = route.name || "media"

  const props = defineProps({
    allowMultiSelect: {
      type: Boolean,
      default: true,
    },

    allowedMediaType: {
      type: String,
      default: "all",
    },

    allowCreationInList: {
      type: Boolean,
      default: true,
    },

    imageCount: {
      type: Number,
      default: 0,
    },

    listInitialTags: {
      type: Array,
      default: [],
    },

    label: {
      type: String,
      required: true,
    },

    media: {
      type: Array,
      default: null,
    },

    mediaFilterType: {
      type: String,
      default: "all",
    },

    restriction: {
      type: String,
      default: null,
    },

    showAddToMessageButton: {
      type: Boolean,
      default: true,
    },

    showAddToPostButton: {
      type: Boolean,
      default: true,
    },

    showSetProfilePhotoButton: {
      type: Boolean,
      default: false,
    },

    showSetProfileBackgroundButton: {
      type: Boolean,
      default: false,
    },

    showUploadButton: {
      type: Boolean,
      default: true,
    },

    sortingOrder: {
      type: String,
      default: "desc",
    },

    tagLists: {
      type: Object,
      required: true,
    },

    videoCount: {
      type: Number,
      default: 0,
    },

    selectedMedia: {
      type: Array,
      default: [],
    },

    inModal: {
      type: Boolean,
      default: false,
    },
  })

  const userStore = useUserStore()
  const itemSelectionList = ref(props.selectedMedia || [])
  const numberOfItemsSelected = ref(props.selectedMedia?.length || 0)
  const addToListModalShowing = ref(false)
  const deleteMediaConfirmationShowing = ref(false)
  const tagMediaModalOpen = ref(false)
  const newTags = ref("")
  const tagsInput = ref(null)
  const emit = defineEmits([
    "cancel",
    "change-filter-type",
    "navigate-back",
    "refresh-media",
    "sorting-order-change",
    "attach-media",
    "update-medium",
  ])

  const mimeTypes = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "video/mp4",
    "video/mov",
    "video/quicktime",
    "video/avi",
    "video/mkv",
    "video/wmv",
  ]

  const toggleSelection = (media) => {
    if (props.allowMultiSelect) {
      if (isSelected(media)) {
        numberOfItemsSelected.value--
        itemSelectionList.value = itemSelectionList.value.filter(
          (item) => item.id !== media.id,
        )
      } else {
        itemSelectionList.value.push(media)
        numberOfItemsSelected.value++
      }
    } else {
      if (isSelected(media)) {
        numberOfItemsSelected.value--
        itemSelectionList.value = []
      } else {
        numberOfItemsSelected.value = 1
        itemSelectionList.value = [media]
      }
    }
  }

  // TODO: I'm hacking through this area due to tim. More thought should be given to the management of this.
  const tagsToRemove = ref([])
  const tagsToSubmit = computed(() => {
    return [
      ...new Set(
        newTags.value
          .split(",")
          .concat(itemSelectionList.value.map((m) => m.tags).flat())
          .map((t) => t.trim().slice(0, 50))
          .filter((t) => tagsToRemove.value.indexOf(t) === -1)
          .filter((t) => t.trim() !== ""),
      ),
    ]
  })

  const isSelected = (media) => {
    const selectedMediaIds = itemSelectionList.value.map((m) => m.id)
    if (selectedMediaIds.includes(media.id)) {
      return true
    } else {
      return false
    }
  }

  const clearSelected = () => {
    numberOfItemsSelected.value = 0
    itemSelectionList.value = []
  }

  const openMediaModal = (media, gallery = []) => {
    modal.open(MediaModal, {
      media: media,
      gallery: gallery,
      editable: true,
      price: null,
      creator: media?.creator ? media.creator : userStore,
      createdAt: media.createdAt,

      /* Emit Listeners */
      onClose() {
        modal.close()
      },
      onEdit() {
        editSingleMedia(media)
      },
      onRefresh() {
        emit("refresh-media")
      },
      onUpdateMedium(medium) {
        emit("update-medium", medium)
      },
    })
  }

  const handleMediaPreview = (media) => {
    if (userStore.loggedIn) {
      openMediaModal(media, [media])
    } else {
      navigateTo(`/media/${media.id}`)
    }
  }

  const showDeleteConfirmation = () => {
    deleteMediaConfirmationShowing.value = true
  }

  const config = useRuntimeConfig()
  const deleteSelected = async () => {
    await useAuthFetch(`${config.public.API_URL}/api/${areaName}`, {
      method: "DELETE",
      body: {
        media_ids: itemSelectionList.value.map((i) => i.id),
      },
    })

    deleteMediaConfirmationShowing.value = false
    numberOfItemsSelected.value--

    emit("refresh-media")
  }

  const tagSelected = () => {
    tagMediaModalOpen.value = true
  }

  const editSingleMedia = (media) => {
    itemSelectionList.value.push(media)
    tagMediaModalOpen.value = true
  }

  const removeTagToSubmit = (tag) => {
    tagsToRemove.value.push(tag)
    newTags.value = newTags.value
      .split(",")
      .filter((t) => t.trim() !== tag)
      .join(",")
  }

  const updateMedia = async () => {
    await useAuthFetch(`${config.public.API_URL}/api/${areaName}/bulk`, {
      method: "POST",
      body: {
        media_ids: itemSelectionList.value.map((i) => i.id),
        new_tags: tagsToSubmit.value,
      },
    })

    tagMediaModalOpen.value = false

    emit("refresh-media")
  }

  const selectionPreviewThumbnails = computed(() => {
    return itemSelectionList.value.map((media) => media.signedThumbnailUrl)
  })

  const uploadComplete = () => {
    setTimeout(() => {
      emit("refresh-media")
    }, 1000)
  }

  const attachSelectedMedia = (type) => {
    if (["post", "message"].includes(type)) {
      const data = itemSelectionList.value.map((x) => {
        if (x.nsfw) {
          return {
            id: x.id,
            signedThumbnailUrl: x.signedThumbnailUrl,
            nsfw: x.nsfw,
          }
        } else {
          return {
            id: x.id,
            signedThumbnailUrl: x.signedThumbnailUrl,
            nsfw: x.internalTags?.includes("nsfw"),
          }
        }
      })
      emit("attach-media", type, data)
    } else if (["avatar", "background"].includes(type)) {
      const data = itemSelectionList.value.map((x) => {
        return {
          id: x.id,
          mediaUrl: x.resourceUrl,
        }
      })
      emit("attach-media", type, data)
    }
  }
</script>
