<template>
  <div class="flex flex-col gap-1">
    <div v-for="(group, groupIndex) in linkGroups">
      <NuxtLink
        v-for="link in group"
        :to="link.to"
        @click="link.click"
        class="link"
        :class="{ active: isPathActive(link.to) }"
      >
        <font-awesome-icon v-if="link.icon" :icon="link.icon" />
        <span v-html="link.label" />
      </NuxtLink>
      <UDivider v-if="groupIndex < linkGroups.length - 1" class="mt-2" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useRoute } from "vue-router"

  const props = defineProps({
    links: {
      type: Array,
      required: true,
    },
  })

  const route = useRoute()
  const currentPath = computed(() => route.path)
  const isPathActive = (path: string) => {
    return currentPath.value == path
  }
  const linkGroups = computed(() => {
    if (props.links[0].constructor === Array) {
      return props.links
    } else {
      return [props.links]
    }
  })
</script>

<style lang="scss" scoped>
  .link {
    @apply w-full flex flex-row items-center gap-3 px-3 py-1.5 rounded-md text-sm text-cool-800 font-semibold dark:text-white cursor-pointer;

    .icon {
      @apply mx-auto h-5 w-5 lg:mx-0;
    }

    &:hover {
      @apply bg-cool-100 dark:bg-[#2B3236];
    }
  }
</style>
