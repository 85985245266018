<template>
  <!-- Action Button -->
  <Button
    :theme="theme"
    :size="size"
    :block="block"
    :class="buttonClass"
    :iconOnly="buttonIconOnly"
    :shape="shape"
    class="launcher"
    @click="handleOpenModal"
  >
    <font-awesome-icon
      :icon="icon"
      class="text-[#EA1662] dark:text-[#F2699B]"
    />
    {{ label }}
  </Button>

  <!-- Modal: Complete your profile -->
  <CompleteProfileModal
    title="Before you post let's complete your profile"
    :open="profileModal"
    @close="profileModal = false"
  />

  <ClientOnly>
    <UModal v-model="displayModal" :preventClose="true" :ui="modalStyles">
      <Modal :title="modalLabel" @close="handleCloseModal">
        <div v-if="showDatePicker" id="datepicker" class="flex flex-col gap-1">
          <VDatePicker
            v-model="scheduledDateTime"
            mode="date"
            color="pink"
            :is-dark="isDarkMode"
            :min-date="new Date()"
            transition="fade"
            borderless
            transparent
            expanded
          />
          <hr class="divider" />
          <div class="flex flex-row justify-between items-center w-full gap-2">
            <button class="gray-btn" @click="handleOpenTimePicker">
              <font-awesome-icon
                icon="fa-light fa-clock"
                class="fa-fw time-icon"
              />
              <span class="dark:text-white">
                {{ formattedTime }}
              </span>
            </button>
            <button class="pink-btn" @click="handleDateScheduled">
              Schedule
            </button>
          </div>
        </div>

        <div v-if="showTimePicker" id="timepicker" class="flex flex-col gap-1">
          <div
            class="max-h-[250px] overflow-y-hidden overflow-y-scroll no-scrollbar mb-4"
          >
            <div class="grid grid-cols-2 grid-rows-24 gap-2">
              <button
                v-for="(time, index) in timeOptions"
                :key="time.label"
                class="outline-btn"
                :class="{ highlight: time.selected }"
                @click="handleTimeSelection(index)"
              >
                <span class="px-3 text-sm">{{ time.label }}</span>
              </button>
            </div>
          </div>
          <hr class="divider" />
          <div class="flex flex-row justify-between items-center w-full gap-2">
            <button class="gray-btn" @click="handleOpenDatePicker">
              <font-awesome-icon
                icon="fa-light fa-calendar"
                class="fa-fw time-icon"
              />
              <span class="dark:text-white">
                {{ formattedDate }}
              </span>
            </button>
            <button class="pink-btn" @click="handleDateScheduled">
              Schedule
            </button>
          </div>
        </div>
      </Modal>
    </UModal>
  </ClientOnly>
</template>

<script setup>
  /******************************************************
   *   	Setup Define
   *****************************************************/
  const { profileComplete } = useProfileCompletion()

  const props = defineProps({
    block: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "xl",
    },
    shape: {
      type: String,
      default: "rounded",
    },
    icon: {
      type: String,
      default: "fa-solid fa-plus",
    },
  })

  const emit = defineEmits(["set-datetime", "clear-datetime"])

  /******************************************************
   *   	Composables, Refs & Variables
   *****************************************************/
  const colorMode = useColorMode()
  const dayjs = useDayjs()

  const today = dayjs().toDate()

  const displayModal = ref(false)
  const profileModal = ref(false)
  const showDatePicker = ref(true)
  const showTimePicker = ref(false)
  const scheduledDateTime = ref(today)
  const selectedTime = ref(dayjs(today).format("hh:mm A"))

  const modalStyles = {
    base: "sm:!max-w-[320px] sm:!min-w-[320px]",
    rounded: "rounded-xl",
    background: "dark:bg-[#1B2022]",
    container: "items-start md:items-center",
  }

  const timeOptions = ref([
    { selected: false, label: "6:00 AM", hour: 6, minutes: 0 },
    { selected: false, label: "6:30 AM", hour: 6, minutes: 30 },
    { selected: false, label: "7:00 AM", hour: 7, minutes: 0 },
    { selected: false, label: "7:30 AM", hour: 7, minutes: 30 },
    { selected: false, label: "8:00 AM", hour: 8, minutes: 0 },
    { selected: false, label: "8:30 AM", hour: 8, minutes: 30 },
    { selected: false, label: "9:00 AM", hour: 9, minutes: 0 },
    { selected: false, label: "9:30 AM", hour: 9, minutes: 30 },
    { selected: false, label: "10:00 AM", hour: 10, minutes: 0 },
    { selected: false, label: "10:30 AM", hour: 10, minutes: 30 },
    { selected: false, label: "11:00 AM", hour: 11, minutes: 0 },
    { selected: false, label: "11:30 AM", hour: 11, minutes: 30 },
    { selected: false, label: "12:00 PM", hour: 12, minutes: 0 },
    { selected: false, label: "12:30 PM", hour: 12, minutes: 30 },
    { selected: false, label: "1:00 PM", hour: 13, minutes: 0 },
    { selected: false, label: "1:30 PM", hour: 13, minutes: 30 },
    { selected: false, label: "2:00 PM", hour: 14, minutes: 0 },
    { selected: false, label: "2:30 PM", hour: 14, minutes: 30 },
    { selected: false, label: "3:00 PM", hour: 15, minutes: 0 },
    { selected: false, label: "3:30 PM", hour: 15, minutes: 30 },
    { selected: false, label: "4:00 PM", hour: 16, minutes: 0 },
    { selected: false, label: "4:30 PM", hour: 16, minutes: 30 },
    { selected: false, label: "5:00 PM", hour: 17, minutes: 0 },
    { selected: false, label: "5:30 PM", hour: 17, minutes: 30 },
    { selected: false, label: "6:00 PM", hour: 18, minutes: 0 },
    { selected: false, label: "6:30 PM", hour: 18, minutes: 30 },
    { selected: false, label: "7:00 PM", hour: 19, minutes: 0 },
    { selected: false, label: "7:30 PM", hour: 19, minutes: 30 },
    { selected: false, label: "8:00 PM", hour: 20, minutes: 0 },
    { selected: false, label: "8:30 PM", hour: 20, minutes: 30 },
    { selected: false, label: "9:00 PM", hour: 21, minutes: 0 },
    { selected: false, label: "9:30 PM", hour: 21, minutes: 30 },
    { selected: false, label: "10:00 PM", hour: 22, minutes: 0 },
    { selected: false, label: "10:30 PM", hour: 22, minutes: 30 },
    { selected: false, label: "11:00 PM", hour: 23, minutes: 0 },
    { selected: false, label: "11:30 PM", hour: 23, minutes: 30 },
    { selected: false, label: "12:00 AM", hour: 0, minutes: 0 },
    { selected: false, label: "12:30 AM", hour: 0, minutes: 30 },
    { selected: false, label: "1:00 AM", hour: 1, minutes: 0 },
    { selected: false, label: "1:30 AM", hour: 1, minutes: 30 },
    { selected: false, label: "2:00 AM", hour: 2, minutes: 0 },
    { selected: false, label: "2:30 AM", hour: 2, minutes: 30 },
    { selected: false, label: "3:00 AM", hour: 3, minutes: 0 },
    { selected: false, label: "3:30 AM", hour: 3, minutes: 30 },
    { selected: false, label: "4:00 AM", hour: 4, minutes: 0 },
    { selected: false, label: "4:30 AM", hour: 4, minutes: 30 },
    { selected: false, label: "5:00 AM", hour: 5, minutes: 0 },
    { selected: false, label: "5:30 AM", hour: 5, minutes: 30 },
  ])

  /******************************************************
   *   	Computed Functions
   *****************************************************/
  const buttonClass = computed(() => {
    return props.theme != "ghost" ? "min-h-[44px]" : ""
  })

  const buttonIconOnly = computed(() => {
    return !props.label
  })

  const isDarkMode = computed(() => {
    return colorMode.value === "dark"
  })

  const formattedDate = computed(() => {
    return dayjs(scheduledDateTime.value).format("MMM D")
  })

  const formattedTime = computed(() => {
    return dayjs(scheduledDateTime.value).format("hh:mm A")
  })

  const modalLabel = computed(() => {
    return showDatePicker.value ? "Schedule - Date" : "Schedule - Time"
  })

  const deselectAllTimes = () => {
    timeOptions.value.forEach((time) => (time.selected = false))
  }

  /******************************************************
   *   	Event Handlers
   *****************************************************/
  const handleOpenModal = () => {
    if (profileComplete.value) {
      emit("clear-datetime")
      scheduledDateTime.value = new Date()
      displayModal.value = true
    } else {
      profileModal.value = true
    }
  }

  const handleCloseModal = () => {
    displayModal.value = false
    showDatePicker.value = true
    showTimePicker.value = false
    scheduledDateTime.value = new Date()
    emit("set-datetime", "")
  }

  const handleDateScheduled = (value) => {
    showDatePicker.value = true
    showTimePicker.value = false
    displayModal.value = false
    emit("set-datetime", scheduledDateTime.value)
  }

  const handleTimeSelection = (index) => {
    deselectAllTimes()
    timeOptions.value[index].selected = true
    scheduledDateTime.value = dayjs(scheduledDateTime.value)
      .hour(timeOptions.value[index].hour)
      .minute(timeOptions.value[index].minutes)
      .toDate()
  }

  const handleOpenTimePicker = () => {
    showDatePicker.value = false
    showTimePicker.value = true
  }

  const handleOpenDatePicker = () => {
    showTimePicker.value = false
    showDatePicker.value = true
  }
</script>

<style lang="scss">
  .launcher {
    @apply dark:text-[#555f63] dark:hover:text-[#9ca3af];
  }

  #datepicker {
    .vc-weekday {
      @apply text-[#ACABAB] dark:text-[#4B575D];
    }

    .vc-day-content {
      @apply text-[#828282] dark:text-[#4B575D];

      &.vc-disabled {
        @apply text-[#E4E4E4] dark:text-[#2B3236];
      }
    }

    .vc-highlight-content-solid {
      @apply bg-pink-600 dark:bg-pink-500;
      @apply text-white dark:text-white;
    }
  }

  .divider {
    @apply mb-4;
    @apply dark:border-t-[#2B3236];
  }

  .gray-btn {
    @apply rounded-full py-2 mb-3 w-full font-semibold;
    @apply bg-[#F4F4F4] dark:bg-[#2B3236];
    @apply hover:bg-[#E4E4E4] dark:hover:bg-[#3B444A];

    .time-icon {
      @apply dark:text-[#4B575D] mr-2;
    }
  }

  .pink-btn {
    @apply rounded-full py-2 mb-3 w-full font-semibold;
    @apply text-white dark:text-white bg-pink-600 dark:bg-pink-500;
    @apply hover:bg-pink-700 dark:hover:bg-pink-600;
  }

  .outline-btn {
    @apply text-center text-sm rounded-lg py-2 mb-1 w-full font-semibold;
    @apply border-[1.5px] border-[#E4E4E4] dark:border-[#4B575D] dark:text-[#4B575D];
    @apply hover:border-0 hover:bg-[#F4F4F4] dark:hover:border-0 dark:hover:bg-pink-500 dark:hover:text-white;

    &.highlight {
      @apply border-0 bg-[#E4E4E4] dark:border-0 dark:bg-pink-500 dark:text-white;
    }
  }
</style>
