<template>
  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <div class="site-expanded-nav">
      <div v-if="userStore.loggedIn" class="container">
        <div class="user-details">
          <IdentityCard :user="userStore" />
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            class="pb-1 pl-6"
            @click="close"
          />
        </div>

        <TokenCountWithButton
          size="xs"
          buttonText="Buy More"
          :user="userStore"
        />
      </div>

      <div v-else class="flex flex-row justify-between items-start">
        <UAvatar icon="i-heroicons-user" />
        <font-awesome-icon
          icon="fa-solid fa-xmark"
          class="pb-1 pl-6"
          @click="close"
        />
      </div>

      <div class="flex flex-col gap-1">
        <VerticalNavigation :links="links" />
      </div>
    </div>
  </transition>
</template>

<script setup>
  import { useRoute } from "vue-router"
  import { useUserStore } from "@/store/user"

  defineProps({
    close: {
      type: Function,
      required: true,
    },
  })

  const route = useRoute()
  const config = useRuntimeConfig()
  const currentPath = computed(() => route.path)
  const isPathActive = (path) => {
    return currentPath.value == path
  }

  const userStore = useUserStore()
  const colorMode = useColorMode()
  const isDark = computed({
    get() {
      return colorMode.value === "dark"
    },
    set() {
      colorMode.preference = colorMode.value === "dark" ? "light" : "dark"
    },
  })

  const links = computed(() => {
    const linkGroups = [[], [], [], [], []]

    if (userStore.loggedIn) {
      if (userStore.approvedCreator) {
        linkGroups[1].push({
          label: "My Profile",
          icon: "fa-light fa-circle-user",
          to: userStore.profileUrl,
        })

        linkGroups[1].push({
          label: "Calendar",
          icon: "fa-light fa-calendar",
          to: "/calendar",
        })
      }

      linkGroups[1].push({
        label: "Purchases",
        icon: "fa-light fa-image",
        to: "/purchases",
      })

      if (userStore.approvedCreator) {
        linkGroups[1].push({
          label: "Media",
          icon: "fa-light fa-image",
          to: "/media",
        })
      }

      linkGroups[1].push({
        label: "Favorites",
        icon: "fa-light fa-user-check",
        to: "/favorites",
      })

      linkGroups[1].push({
        label: "Wallet",
        icon: "fa-light fa-wallet",
        to: "/wallet",
      })

      linkGroups[1].push({
        label: "Settings",
        icon: "fa-light fa-gear",
        to: "/settings",
      })
    }

    if (userStore.loggedIn) {
      if (!userStore.approvedCreator) {
        linkGroups[2].push({
          label: "Become a Creator",
          icon: "fa-light fa-hand-holding-circle-dollar",
          to: "/settings/verification",
        })
      }

      if (!userStore.affiliate) {
        linkGroups[2].push({
          label: "Earn Referral Dollars",
          icon: "fa-light fa-box-dollar",
          to: "/settings/payouts",
        })
      }
    } else {
      linkGroups[1].push({
        label: "Join",
        icon: "fa-light fa-user",
        to: "/join",
      })

      linkGroups[2].push({
        label: "Become a Creator",
        icon: "fa-light fa-hand-holding-circle-dollar",
        to: "/l/creators",
      })
    }

    if (userStore.approvedCreator) {
      // linkGroups[2].push({
      //   label: "Statements",
      //   icon: "fa-light fa-square-dollar",
      //   to: "/statements",
      // })
    }

    if (userStore.approvedCreator || userStore.affiliate) {
      linkGroups[2].push({
        label: "Analytics & Reports",
        icon: "fa-light fa-chart-line-up",
        to: "/analytics",
      })
    }

    linkGroups[3].push({
      label: "Help & Support",
      icon: "fa-light fa-circle-question",
      to: "https://support.favorite.ly",
    })

    let colorModeText = null
    switch (colorMode.value) {
      case "light":
        colorModeText = "Dark"
        break

      default:
        colorModeText = "Light"
        break
    }

    linkGroups[3].push({
      label: colorModeText + " Mode",
      icon: "fa-light fa-sun",
      click: () => (isDark.value = !isDark.value),
    })

    if (userStore.loggedIn) {
      linkGroups[4].push({
        label: "Logout",
        icon: "fa-light fa-right-to-bracket",
        click: userStore.logout,
      })
    } else {
      linkGroups[4].push({
        label: "Login",
        icon: "fa-light fa-right-to-bracket",
        to: "/login",
      })
    }

    return linkGroups
  })
</script>

<style lang="scss" scoped>
  .site-expanded-nav {
    @apply flex flex-col gap-3 p-3;
    @apply dark:bg-[#1B2022];
  }
  .container {
    @apply w-full flex flex-col gap-3;
  }
  .user-details {
    @apply w-full flex flex-row justify-between;
  }
</style>
