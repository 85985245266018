<template>
  <div class="smart-uavatar-wrapper">
    <div
      v-if="isLoading || hasError"
      class="sk-center sk-chase loading-indicator"
    >
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
    </div>

    <UAvatar v-else :src="currentBgImage" v-bind="attrs" />

    <div v-if="hasError && retryCount >= maxRetries" class="error-indicator">
      <span>Failed to load avatar.</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { watchEffect, useAttrs } from "vue"
  import { useBackgroundImageLoader } from "@/composables/useBackgroundImageLoader"

  const props = defineProps({
    /**
     * Placeholder image URL to display when the main image fails to load.
     */
    placeholder: {
      type: String,
      default: "/images/default-avatar.png", // Ensure this path points to a valid image
    },
    /**
     * Interval (in milliseconds) between retry attempts to load the image.
     * This will be modified with a logarithmic factor, depending on the number of retry attempts that have occurred.
     */
    retryInterval: {
      type: Number,
      default: 3000, // Retry every 3 seconds
    },
    /**
     * Maximum number of retry attempts for loading the image.
     */
    maxRetries: {
      type: Number,
      default: 99999,
    },
  })

  const emit = defineEmits<{
    (e: "max-retries-exceeded", src: string): void
  }>()

  const attrs = useAttrs()

  const { placeholder, retryInterval, maxRetries } = props

  const { currentBgImage, hasError, isLoading, retryCount } =
    useBackgroundImageLoader({
      imageUrl: attrs.src as string,
      placeholder,
      retryInterval,
      maxRetries,
    })

  watchEffect(() => {
    if (hasError.value && retryCount.value >= maxRetries) {
      emit("max-retries-exceeded", attrs.src as string)
    }
  })
</script>

<style scoped>
  .smart-uavatar-wrapper {
    position: relative;
    display: inline-block;
  }

  .loading-indicator {
    --sk-color: white;
  }

  .error-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 0, 0, 0.7);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    text-align: center;
  }
</style>
