<template>
  <USelectMenu
    v-model="selectedCountry"
    :multiple="multiple"
    :placeholder="placeholder"
    :options="mappedCountries"
    variant="none"
    :ui="selectButtonStyles"
    :uiMenu="uiMenuStyles"
    size="xl"
    searchable
    value-attribute="key"
    option-attribute="value"
  >
    <template #label>
      <div v-if="!multiple && selectedCountry" class="py-1">
        <div class="flex flex-row items-center gap-1">
          <span>{{ getUnicodeFlagIcon(selectedCountry) }}</span>
          <span class="text-gray-800 dark:text-white">
            {{ countries[selectedCountry] }}
          </span>
          <span class="pl-1 text-sm text-cool-400">
            {{ selectedCountry }}
          </span>
        </div>
      </div>

      <div
        v-if="multiple && selectedCountry.length"
        class="flex flex-row flex-wrap items-center gap-2"
      >
        <div
          v-for="country in selectedCountry"
          class="flex flex-row items-center gap-1 bg-cool-200 rounded-full py-1 px-2 dark:bg-[#2B3236]"
        >
          <span class="text-sm text-cool-400">
            {{ countries[country] }}
          </span>
          <font-awesome-icon
            @click.prevent="removeCountryFromList(country)"
            icon="fa-light fa-circle-xmark"
            class="text-primary-600"
          />
        </div>
      </div>
    </template>

    <template #option="{ option: country }">
      {{ getUnicodeFlagIcon(country.key) }}
      <span>{{ country.value }}</span>
      <span class="text-sm text-cool-400">{{ country.key }}</span>
    </template>
  </USelectMenu>
</template>

<script setup>
  import countries from "countries-list/minimal/countries.en.min"
  import getUnicodeFlagIcon from "country-flag-icons/unicode"

  const props = defineProps({
    modelValue: {
      type: [String, Array],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  })

  const selectedCountry = ref(props.modelValue)
  const priorityCountries = ["United States", "Canada", "United Kingdom"]
  const mappedCountries = Object.entries(countries)
    .map(([key, value]) => ({
      key,
      value,
    }))
    .sort((a, b) => {
      if (priorityCountries.includes(a.value)) {
        return -1
      }
      if (priorityCountries.includes(b.value)) {
        return 1
      }
      return a.value.localeCompare(b.value)
    })

  const emit = defineEmits(["update:modelValue"])

  const selectButtonStyles = {
    rounded: "lg",
    size: { xl: "text-sm" },
    padding: { xl: "px-3 py-2" },
    placeholder: "text-gray-400 dark:text-blue-500",
    variant: {
      none:
        "font-semibold shadow-sm ring-1 ring-[#384247] " +
        "ring-inset ring-[#E4E4E4] dark:ring-[#384247]" +
        "focus:ring-[#f26998] dark:focus:ring-2 dark:focus:ring-[#f26998] " +
        "focus-visible:ring-",
    },
  }

  const uiMenuStyles = {
    background: "bg-white dark:bg-[#1B2022] dark:text-white",
    input:
      "block w-[calc(100%+0.5rem)] focus:ring-transparent text-sm px-3 py-1.5 text-gray-700 dark:text-white bg-white dark:bg-[#1B2022] border-0 border-b border-gray-200 dark:border-gray-700 sticky -top-1 -mt-1 mb-1 -mx-1 z-10 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none",
    option: {
      active: "bg-gray-200 dark:bg-[#2B3236] dark:text-white",
    },
  }

  watch(
    () => props.modelValue,
    (newValue) => {
      if (newValue !== selectedCountry.value) {
        selectedCountry.value = newValue
      }
    },
  )

  watch(selectedCountry, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      emit("update:modelValue", newValue)
    }
  })

  const removeCountryFromList = (country) => {
    selectedCountry.value = selectedCountry.value.filter((c) => c !== country)
  }
</script>
