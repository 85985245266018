<template>
  <div
    v-if="errors.length"
    class="relative flex flex-col items-start justify-center py-2 px-4 mb-4 border-2 border-red-500 rounded-lg text-xs animate-fade-up"
  >
    <div class="w-full flex flex-row justify-between items-center">
      <div>
        <div class="flex flex-col items-start space-y-1">
          <p v-for="err in errors" class="text-red-600">
            {{ err }}
          </p>
        </div>
        <span
          class="absolute left-4 top-[-9px] bg-white dark:bg-[#1b2022] px-2 rounded-full text-red-500"
        >
          errors
        </span>
      </div>
      <font-awesome-icon icon="fa-light fa-close" @click="emit('clear')" />
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    errors: {
      type: Array,
      default: [],
    },
  })

  const emit = defineEmits(["clear"])
</script>
