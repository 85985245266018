export function useProfileCompletion() {
  const userStore = useUserStore()

  const profileItemsToComplete = computed(() => {
    const defaultAvatar = userStore.avatarUrl?.includes("photo_placeholders")
    const defaultBackground =
      userStore.backgroundUrl?.includes("photo_placeholders")

    return {
      "Profile Photo": !defaultAvatar,
      "Background Photo": !defaultBackground,
      "Display Name": !!userStore.displayName,
      Description: !!(userStore?.bio || null),
    }
  })

  const totalProfileCompletionSteps = computed(() => {
    return Object.keys(profileItemsToComplete.value).length
  })

  const currentProfileCompletionStep = computed(() => {
    return Object.values(profileItemsToComplete.value).filter(
      (completed) => completed,
    ).length
  })

  const profileComplete = computed(() => {
    return (
      currentProfileCompletionStep.value === totalProfileCompletionSteps.value
    )
  })

  return {
    profileItemsToComplete,
    totalProfileCompletionSteps,
    currentProfileCompletionStep,
    profileComplete,
  }
}
