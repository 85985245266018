<template>
  <div class="relative animate-fade">
    <div class="flex flex-row items-center justify-center gap-2">
      <!-- Default Button -->
      <Button
        v-if="!hasLockedMedia"
        :theme="theme"
        :size="size"
        @click="handleSubmit"
      >
        {{ buttonLabel }}
      </Button>

      <!-- Token Input with Button -->
      <FormInput
        v-if="hasLockedMedia"
        v-model="tokens"
        type="number"
        placeholder="Tokens.."
        :ui="inputStyles"
        class="animate-fade no-spinners"
        @input="handleTokenInput"
      >
        <template #leading>
          <font-awesome-icon icon="fa-light fa-coins" color="gray" />
        </template>
        <template #trailing>
          <Button size="xs" @click="handleSubmit">
            {{ buttonLabel }}
          </Button>
        </template>
      </FormInput>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    theme: {
      type: String,
      default: "outline",
    },
    size: {
      type: String,
      default: "md",
    },
    state: {
      type: Object,
      required: true,
    },
  })

  const emit = defineEmits(["price-changed", "submit"])

  const mode = computed(() => {
    return props.state.text ? "Update" : "Create"
  })

  const hasLockedMedia = computed(() => {
    const locked = props.state.media.filter((m) => m.locked).length
    if (!locked) {
      emit("price-changed", null)
    }
    return locked
  })

  const buttonLabel = computed(() => {
    return hasLockedMedia.value && !tokens.value
      ? "Set Price"
      : [mode.value, props.state.scheduled ? "Scheduled" : null, "Post"]
          .filter(Boolean)
          .join(" ")
  })

  const tokens = computed(() => {
    return props.state.price
  })

  const handleTokenInput = async (event) => {
    emit("price-changed", event.target.value)
  }

  const handleSubmit = async () => {
    emit("submit")
  }

  const handleSave = () => {
    emit("price-changed", tokens)
  }

  const inputStyles = {
    wrapper: "relative text-sm",
    rounded: "rounded-full",
    padding: "lg",
    variant: {
      none:
        "font-semibold ring-1 ring-[#E4E4E4] " +
        "focus:ring-1 focus:ring-[#f26998] " +
        "dark:ring-[#384247] dark:bg-[#1b2022] " +
        "dark:focus:ring-[#f26998] dark:focus:bg-[#2b3236]",
    },
    icon: {
      trailing: {
        wrapper: "absolute inset-y-0 end-0 flex items-center !px-1",
        pointer: "",
      },
    },
  }
</script>
