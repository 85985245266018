<template>
  <div @drop.prevent="addFiles">
    <input
      ref="fileUpload"
      type="file"
      name="file-uploader"
      class="hidden"
      :multiple="multiple"
      :accept="accepts.join(',')"
      @change="filesChange"
    />

    <slot v-bind:selectFiles="selectFiles">
      <!-- Button with Icon -->
      <Button
        v-if="trigger === 'button'"
        :size="size"
        :theme="buttonTheme"
        @click="selectFiles"
      >
        <template #leading>
          <font-awesome-icon :icon="icon" class="fa-fw" />
        </template>
        {{ buttonText }}
      </Button>

      <!-- Icon Circle -->
      <Button
        v-if="trigger === 'icon'"
        :size="size"
        :theme="buttonTheme"
        :iconOnly="true"
        @click="selectFiles"
      >
        <font-awesome-icon :icon="icon" class="fa-fw" />
      </Button>

      <!-- Toolbar Square Icon -->
      <Button
        v-if="trigger === 'toolbar'"
        @click="selectFiles"
        theme="ghost"
        :iconOnly="true"
        shape="square"
        :size="size"
        class="btn-txt"
      >
        <font-awesome-icon
          :icon="icon"
          size="lg"
          class="fa-fw"
          :class="iconStyles"
        />
      </Button>
    </slot>
  </div>
</template>

<script setup>
  import FileUploaderItem from "@/utils/FileUploaderItem"
  const { profileComplete } = useProfileCompletion()

  const props = defineProps({
    allowNonCreatorUploads: {
      type: Boolean,
      default: false,
    },

    accepts: {
      type: Array,
      required: false,
      default: ["image/*", "video/*"],
    },

    addToMediaLibrary: {
      type: Boolean,
      required: false,
      default: true,
    },

    buttonTheme: {
      type: String,
      default: "primary",
    },

    buttonText: {
      type: String,
      default: "Upload",
    },

    initialTags: {
      type: Array,
      required: false,
      default: [],
    },

    maxUploadSizeInMegabytes: {
      type: Number,
      required: false,
      default: 5000,
    },

    creationDate: {
      type: String,
      required: true,
    },

    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },

    size: {
      type: String,
      required: false,
      default: "md",
    },

    trigger: {
      type: String,
      required: false,
      default: "button",
    },

    icon: {
      type: String,
      required: false,
      default: "fa-light fa-upload",
    },

    iconStyles: {
      type: String,
      default: "",
    },

    useProfileCheck: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits([
    "upload-complete",
    "upload-failed",
    "incomplete-profile",
  ])

  const userStore = useUserStore()
  const fileCount = ref(0)
  const fileUpload = ref(null)
  const errors = ref({})
  const config = useRuntimeConfig()

  const generateUploadSignature = async (payload) => {
    try {
      const response = await $api(
        config.public.API_URL + `/api/media/signature`,
        {
          method: "POST",
          body: {
            data: payload,
            xm: props.allowNonCreatorUploads,
          },
        },
      )
      return response.signature
    } catch (error) {
      const toast = useToast()
      toast.add({ title: useErrorMessage(error.response._data) })
    }
  }

  const completeItemUploadSignature = async (response) => {
    try {
      const uploadResponse = await $api(config.public.API_URL + `/api/media`, {
        method: "POST",
        body: {
          media: {
            add_to_media_library: props.addToMediaLibrary,
            remote_id: response.id,
            original_filename: response.uploadedFile.name,
            type: response.type(),
            url: response.path,
            creation_date: props.creationDate,
            tags: props.initialTags,
          },
          xm: props.allowNonCreatorUploads,
        },
      })
      emit("upload-complete", {
        ...uploadResponse,
        ...{ previewUrl: response.previewUrl },
      })
    } catch (error) {
      // TODO: This error is murky. It's either a runtime error or a fetch error.
      const toast = useToast()
      toast.add({ title: useErrorMessage(error.response._data) })
    }
  }

  const selectFiles = () => {
    if (profileComplete.value === false && props.useProfileCheck) {
      emit("incomplete-profile")
    } else {
      errors.value = {}
      fileUpload.value.click()
    }
  }

  const trackNewUpload = inject("trackNewUpload")
  const updateUploadProgress = inject("updateUploadProgress")
  const uploadComplete = inject("uploadComplete")

  const events = ["dragenter", "dragover", "dragleave", "drop"]
  onMounted(() => {
    events.forEach((eventName) => {
      document.body.addEventListener(eventName, (e) => {
        e.preventDefault()
      })
    })
  })

  onUnmounted(() => {
    events.forEach((eventName) => {
      document.body.removeEventListener(eventName, (e) => {
        e.preventDefault()
      })
    })
  })

  const addFiles = (e) => {
    e.dataTransfer.dropEffect = "copy"
    const keys = Object.keys(e.dataTransfer.files)
    const values = keys.map((v) => {
      return e.dataTransfer.files[v]
    })

    for (let i = 0; i < values.length; i++) {
      const uploader = new FileUploaderItem({
        profileUrl: userStore.profileUrl,
        location: window.location,
        userId: userStore.userId,
        uploadedFile: values[i],
        previewUrl: URL.createObjectURL(values[i]),
        maxUploadSizeInMegabytes: 5000,
        progressCallback: (upload) => {
          updateUploadProgress(upload)
        },
        signatureCallback: generateUploadSignature,
        completeCallback: (upload) => {
          completeItemUploadSignature(upload)
          uploadComplete(upload)
        },
        errorCallback: (error) => {
          const toast = useToast()
          toast.add({ title: "Upload Failed", description: error })
        },
      })

      trackNewUpload(uploader)

      uploader.startUpload()
    }
  }

  const filesChange = async () => {
    let fileList = fileUpload.value.files
    fileCount.value = fileList

    if (!fileList.length) return

    for (var i = 0; i < fileList.length; i++) {
      const uploader = new FileUploaderItem({
        profileUrl: userStore.profileUrl,
        location: window.location,
        userId: userStore.userId,
        uploadedFile: fileList[i],
        previewUrl: URL.createObjectURL(fileList[i]),
        maxUploadSizeInMegabytes: 5000,
        progressCallback: (upload) => {
          updateUploadProgress(upload)
        },
        signatureCallback: generateUploadSignature,
        completeCallback: (upload) => {
          completeItemUploadSignature(upload)
          uploadComplete(upload)
        },
        errorCallback: (error) => {
          const toast = useToast()
          toast.add({ title: "Upload Failed", description: error })
        },
      })

      trackNewUpload(uploader)

      uploader.startUpload()
    }
  }
</script>
