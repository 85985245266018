<template>
  <div class="flex flex-row items-center justify-start">
    <div
      v-for="(image, index) in previewImages.filter((image) => image)"
      :key="image.id || index"
      :class="imageSizeClasses"
      class="h-12 w-12 aspect-1 -ml-2"
    >
      <BackgroundImage
        :imageUrl="image"
        :placeholder="placeholderUrl"
        :retryInterval="3000"
        :maxRetries="99999"
        :additionalClasses="backgroundClasses"
        @max-retries-exceeded="handleMaxRetries"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import BackgroundImage from "@/components/Media/BackgroundImage.vue"

  const props = defineProps({
    previewImages: {
      type: Array,
      required: true,
      // Example structure: [{ id: 1, url: 'https://example.com/image1.jpg' }, ...]
    },

    size: {
      type: String,
      default: "xs",
    },
  })

  // Define the placeholder URL
  const placeholderUrl = "/images/transparent.gif"

  const imageSizeClasses = computed(() => {
    switch (props.size) {
      case "xs":
        return "h-8 w-8"
      case "sm":
        return "h-12 w-12"
      case "md":
        return "h-16 w-16"
      case "lg":
        return "h-20 w-20"
      case "xl":
        return "h-24 w-24"
      default:
        return "h-8 w-8"
    }
  })

  const backgroundClasses = `${imageSizeClasses} rounded-lg border-2 border-white transition-colors bg-cover bg-center bg-no-repeat group-hover:border-cool-200 dark:border-[#1B2022] group-hover:dark:border-cool-800`

  const handleMaxRetries = (failedImageUrl: string) => {
    console.warn(
      `Failed to load image after maximum retries: ${failedImageUrl}`,
    )
  }
</script>
