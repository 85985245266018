<template>
  <div
    :class="containerStyles"
    :style="backgroundImageStyle(currentSrc)"
    role="img"
    :aria-label="isVideo ? 'Video asset' : 'Image asset'"
  >
    <div
      v-if="notifyLoading() && !isVideo"
      class="absolute min-h-4 inset-0 flex items-center justify-center"
    >
      <span class="text-sm text-white-600">Loading...</span>
      <div class="w-1/5 sk-chase">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
    </div>
    <div
      v-else-if="notifyLoading() && isVideo"
      class="absolute inset-0 flex items-center justify-center"
    >
      Loading...
      <div class="w-1/5 sk-chase">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
    </div>
    <div
      v-if="hasError.value && retryCount >= maxRetries"
      class="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50"
    >
      <span class="text-sm text-red-600">Failed to load asset.</span>
    </div>

    <BlurHashThumb
      v-if="canBlur()"
      v-bind="$attrs"
      :blur-hash="blurhash"
      :blur-width="blurWidth"
      :blur-height="blurHeight"
    />
    <img
      v-else-if="!backgroundOnly && !isVideo"
      :src="currentSrc"
      @load="handleLoad"
      @error="handleError"
      alt=""
      class="object-contain h-auto w-auto"
    />
    <VideoPlayer
      v-else-if="!backgroundOnly && isVideo"
      :key="videoKey"
      :src="currentSrc"
      @error="handleError"
      @loaded="handleLoad"
    />
  </div>
</template>

<script setup lang="ts">
  import { ref, watch, onMounted, onUnmounted } from "vue"
  import VideoPlayer from "../VideoPlayer.vue"

  const emit = defineEmits(["max-retries-exceeded"])

  const props = defineProps({
    src: {
      type: String,
      required: true,
    },

    isVideo: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: "/images/transparent.gif",
    },

    blurhash: {
      type: String,
      default: "",
    },

    blurWidth: {
      type: Number,
      default: 0,
    },

    blurHeight: {
      type: Number,
      default: 0,
    },

    retryInterval: {
      type: Number,
      default: 3000, // Retry every 3 seconds
    },

    maxRetries: {
      type: Number,
      default: 99999, // Maximum number of retries
    },

    backgroundNone: {
      type: Boolean,
      default: false,
    },

    backgroundOnly: {
      type: Boolean,
      default: false,
    },

    continueNotification: {
      // Normally, for images, the loading notifier only displays for the timeframe of the first image load, because it is assumed that the placeholder will be desired after that. If the loading notifier should continue displaying on error, make this true.
      type: Boolean,
      default: false,
    },

    containerClasses: {
      type: String,
      default: "", // Additional CSS classes for the container
    },
  })

  const {
    src,
    isVideo,
    placeholder,
    retryInterval,
    maxRetries,
    backgroundOnly,
    continueNotification,
    containerClasses,
  } = props

  const currentSrc = ref(src)
  const hasError = ref(false)
  const isLoading = ref(true)
  const hasLoaded = ref(false)
  const retryCount = ref(0)

  const notifyLoading = () => {
    console.log("notifyLoading")
    console.log(
      `(${isVideo} || ${continueNotification}) ? ${
        hasError.value
      } : ${!hasLoaded.value}`,
    )
    return isVideo || continueNotification ? hasError.value : !hasLoaded.value
  }

  const canBlur = () => {
    return hasError.value && !!props.blurhash
  }

  const blurContainerClass = () => {
    return canBlur() ? "assetWrapperBlur" : ""
  }

  const backgroundImageStyle = (src: String) => {
    if (!props.backgroundNone) {
      return { backgroundImage: `url('${src}')` }
    }
  }

  const containerStyles = computed(() => {
    return ["relative", containerClasses, blurContainerClass()].join(" ")
  })

  const videoKey = ref(0) // Used to force re-render VideoPlayer

  let retryTimer: object | null = null

  const getRetryInterval = (count: number): number => {
    return retryInterval * Math.log(count + 2)
  }

  const attemptLoad = () => {
    isLoading.value = true
    if (isVideo) {
      const video = document.createElement("video")
      video.src = src
      video.preload = "auto"

      const onLoadedData = () => {
        hasError.value = false
        currentSrc.value = src
        isLoading.value = false
        hasLoaded.value = true
        stopRetry()
        retryCount.value = 0
        cleanup()
      }

      const onError = () => {
        hasLoaded.value = true
        hasError.value = true
        currentSrc.value = placeholder
        isLoading.value = false
        cleanup()
        if (retryCount.value < maxRetries) {
          startRetry()
        }
      }
      const cleanup = () => {
        video.removeEventListener("loadeddata", onLoadedData)
        video.removeEventListener("error", onError)
      }

      video.addEventListener("loadeddata", onLoadedData)
      video.addEventListener("error", onError)

      // Start loading the video
      video.load()
    } else {
      const img = new Image()
      img.src = src
      img.onload = () => {
        hasError.value = false
        currentSrc.value = src
        isLoading.value = false
        hasLoaded.value = true
        stopRetry()
        retryCount.value = 0
      }
      img.onerror = () => {
        hasLoaded.value = true
        hasError.value = true
        currentSrc.value = placeholder
        isLoading.value = false
        if (retryCount.value < maxRetries) {
          startRetry()
        }
      }
    }
  }

  const handleLoad = () => {
    hasError.value = false
    isLoading.value = false
    stopRetry()
    retryCount.value = 0
  }

  const handleError = () => {
    hasError.value = true
    currentSrc.value = placeholder
    isLoading.value = false
    if (retryCount.value < maxRetries) {
      startRetry()
    } else {
      emit("max-retries-exceeded")
    }
  }

  const nextTrigger = (count: number) => {
    return new Date().getTime() + getRetryInterval(count)
  }

  const debugging = false
  function debugMessage(message) {
    if (debugging) {
      console.log(message)
    }
  }

  const startRetry = () => {
    if (!retryTimer && retryCount.value < maxRetries) {
      let trigger = nextTrigger(retryCount.value)
      retryTimer = window.setInterval(() => {
        if (new Date().getTime() > trigger) {
          retryCount.value += 1
          debugMessage(
            `Retrying to load asset (${retryCount.value}/${maxRetries}): ${src}`,
          )
          currentSrc.value = src
          videoKey.value += 1 // Change key to force re-render
          trigger = nextTrigger(retryCount.value)
          attemptLoad()
          if (retryTimer) {
            debugMessage(
              `Next reload in ${trigger - new Date().getTime()} milliseconds`,
            )
          }
        }
      }, 100) // Adjusted interval to 100ms for better performance
    }
  }

  const stopRetry = () => {
    if (retryTimer) {
      clearInterval(retryTimer)
      retryTimer = null
    }
  }

  // Watch for changes in src prop to reset state
  watch(
    () => src,
    (newSrc) => {
      currentSrc.value = newSrc
      hasError.value = false
      isLoading.value = true
      retryCount.value = 0
      videoKey.value += 1 // Reset VideoPlayer
      stopRetry()
      attemptLoad()
    },
  )

  onMounted(() => {
    attemptLoad()
  })

  onUnmounted(() => {
    stopRetry()
  })
</script>

<style scoped>
  .relative {
    position: relative;
  }
  .hidden {
    display: none;
  }

  .assetWrapperBlur {
    width: 100%;
    height: 100%;
  }

  .sk-chase {
    --sk-color: white;
    --sk-size: 10%;
  }
</style>
