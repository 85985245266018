<template>
  <div class="age-confirmation-modal">
    <div class="heading">
      <Logo variant="square" class="focus:outline-none" />
      <p>
        <span class="font-bold text-red-600 text-lg ml-1">18+</span>
        This site contains sexually explicit material.
      </p>
    </div>

    <div class="body">
      <p>
        Enter ONLY if you are over 18. By using the site, I accept the
        <a
          href="/general-terms-of-service"
          target="_blank"
          class="focus:outline-none"
        >
          Terms of Service
        </a>
        and the
        <a href="/privacy" target="_blank">Privacy Policy.</a>
        &nbsp;
      </p>
    </div>
    <div class="actions">
      <Button
        theme="outline"
        @click="navigateTo('http://google.com', { external: true })"
      >
        No, I'll leave.
      </Button>
      <Button @click="$emit('confirm')">I Agree</Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .age-confirmation-modal {
    @apply flex flex-col gap-6 p-8;
    @apply dark:bg-[#1B2022];
  }
  .heading {
    @apply flex flex-col text-center sm:flex-row items-center gap-3 font-semibold text-white/50;
  }
  .body {
    @apply mb-2;
  }
  .actions {
    @apply flex flex-row items-center justify-end gap-1.5;
  }
</style>
