<template>
  <div class="search-results-posts-container scrollable no-scrollbar">
    <div v-if="posts.length" class="flex flex-col gap-2">
      <Panel v-for="post in posts" :key="post.id">
        <FeedPost :post="post" />
      </Panel>

      <div v-element-visibility="loadMore">
        <div class="h-4" />
        <div v-if="thisIsTheEnd" class="flex justify-center my-12">
          <Logo variant="square" size="h-16" />
        </div>
      </div>
    </div>

    <div v-else class="flex flex-col justify-center items-center gap-12">
      <p class="text-lg text-gray-500 my-12">No posts found</p>
      <Logo variant="square" size="h-16" />
    </div>
  </div>
</template>

<script setup>
  import { vElementVisibility } from "@vueuse/components"

  const props = defineProps({
    lastPage: Number,
    posts: Array,
    pageNumber: Number,
  })

  const thisIsTheEnd = computed(() => props.pageNumber === props.lastPage)
  const emit = defineEmits(["loadMore"])

  const loadMore = () => {
    emit("loadMore")
  }
</script>

<style lang="scss" scoped>
  .search-results-posts-container {
    @apply w-full;
  }
  .scrollable {
    @apply h-[calc(100vh-188.5px)] overflow-y-auto;
  }
</style>
