<template>
  <Button
    v-if="!userStore.loggedIn"
    theme="primary"
    :block="block"
    :size="size"
    @click="loginModalOpen = true"
  >
    View now!
  </Button>
  <Button
    v-else-if="userStore.loggedIn && !owned && postCost > 0 && !postPurchased"
    :id="`post-unlock-btn-${post.id}`"
    theme="primary"
    :block="block"
    :size="size"
    class=""
    @click="handlePostPurchase"
  >
    Unlock for {{ postCost }} tokens
  </Button>
  <!-- <Button
    v-else-if="userStore.loggedIn && totalMediaCount > 4"
    theme="primary"
    :block="block"
    :size="size"
    @click="() => {}"
  >
    View Media
  </Button> -->
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const props = defineProps({
    post: {
      type: Object,
      required: true,
    },
    block: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "md",
    },
    totalMediaCount: {
      type: Number,
      default: 0,
    },
  })

  const emit = defineEmits(["post-purchased"])

  const loginModalOpen = inject("loginModalOpen")
  const userStore = useUserStore()
  const postPurchased = ref(props.post.purchased)
  const postCost = ref(props.post.tokenCount)

  const handlePostPurchase = () => {
    emit("post-purchased", props.post.id)
  }

  const owned = computed(() => {
    return props.post.creator.username == userStore.username
  })

  const showUnlockButton = computed(() => {})
</script>
