<template>
  <div class="w-full flex flex-col gap-4">
    <div class="w-full flex flex-row items-ceneter justify-between">
      <IdentityCard :user="post.creator" />

      <ClientOnly>
        <div class="flex flex-row items-center gap-4">
          <NuxtLink
            :to="`/${post.creator.username}/posts/${post.id}`"
            class="text-xs text-gray-400 whitespace-nowrap"
          >
            {{ $dayjs(new Date(post.createdAt)).format("MMM D") }}
          </NuxtLink>

          <ClientOnly>
            <UPopover
              :popper="{ placement: 'left' }"
              v-if="post.creator.username == userStore.username"
            >
              <font-awesome-icon
                icon="fa-light fa-ellipsis"
                class="text-gray-400"
              />
              <template #panel>
                <div class="flex flex-col gap-1 p-2">
                  <NuxtLink class="link" @click="deletePost(post.id)">
                    <font-awesome-icon icon="fa-light fa-trash" />
                    <span class="text-pink-600">Delete Post</span>
                  </NuxtLink>
                </div>
              </template>
            </UPopover>
          </ClientOnly>
        </div>
      </ClientOnly>
    </div>

    <p v-html="post.text" />

    <MediaPackSummary
      :image-count="imageCount"
      :video-count="videoCount"
      :free="free"
      :token-count="post.tokenCount"
      class="text-gray-400"
    />

    <div
      v-if="post.media"
      class="grid grid-cols-3 gap-2"
      :class="{ '!grid-cols-2': sortedMedia.length == 2 }"
    >
      <MediaThumb
        v-for="(item, i) in sortedMedia"
        :class="[mediaThumbClasses, lastClass(i)]"
        :creator="post.creator"
        :key="item.id"
        :blurhash="item.blurhash"
        :locked="item.locked"
        :duration="$numberToHuman(item.durationInSeconds)"
        :originalHeight="item.height"
        :originalWidth="item.width"
        :thumbnail="item.signedThumbnailUrl"
        :type="item.type"
        :src="item.signedUrl"
      />
    </div>

    <PostUnlockButton :post="post" @post-purchased="handlePurchase" />

    <div class="flex flex-row items-center justify-between">
      <div class="flex flex-row gap-4">
        <NuxtLink @click="toggleLike(post.id)">
          <font-awesome-icon
            v-if="!like"
            icon="fa-light fa-heart"
            class="text-lg text-gray-400"
          />
          <font-awesome-icon
            v-else
            icon="fa-solid fa-heart"
            class="text-lg text-pink-600"
          />
        </NuxtLink>
        <NuxtLink :to="`/${post.creator.username}/posts/${post.id}`">
          <font-awesome-icon
            icon="fa-light fa-comment"
            class="text-lg text-gray-400"
          />
        </NuxtLink>
        <!--
        <font-awesome-icon
          icon="fa-light fa-bookmark"
          class="text-lg text-gray-400"
        />
        -->
      </div>
      <div class="flex flex-row gap-2 text-xs text-gray-400">
        <NuxtLink :to="`/${post.creator.id}/posts/${post.id}`">
          {{ $numberToHuman(likes) }} likes
        </NuxtLink>
        <span>&bull;</span>
        <NuxtLink :to="`/${post.creator.id}/posts/${post.id}`">
          {{ $numberToHuman(post.commentCount) }} comments
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const userStore = useUserStore()

  const runtimeConfig = useRuntimeConfig()
  const props = defineProps({
    post: {
      type: Object,
      required: true,
    },
  })

  const emit = defineEmits(["broadcast-feed-update"])

  const items = props.post.media
  const like = ref(props.post.likedByUser)
  const likes = ref(props.post.likesCount)

  const free = computed(() => {
    return props.post.tokenCount == 0
  })

  const imageCount = computed(() => {
    return items.filter((item) => item.type == "image").length
  })

  const videoCount = computed(() => {
    return items.filter((item) => item.type == "video").length
  })

  const hasVideos = computed(() => {
    return videoCount > 0
  })

  const deletePost = async (postId) => {
    const { pending, data, error } = await useAuthFetch(
      runtimeConfig.public.API_URL + `/api/posts/${postId}`,
      { method: "DELETE" },
    )

    if (error.value) {
      const toast = useToast()
      const errors = useErrorMessage(error.value.data)
      toast.add({ title: errors, color: "red" })
    } else {
      emit("broadcast-feed-update")
    }
  }

  const route = useRoute()
  const toggleLike = async (postId) => {
    if (userStore.loggedIn) {
      const method = like.value ? "DELETE" : "POST"
      const { pending, data, error } = await useAuthFetch(
        runtimeConfig.public.API_URL + `/api/posts/${postId}/like`,
        { method: method },
      )

      if (error.value) {
        console.log(error.value)
      } else {
        like.value = !like.value
        if (like.value) {
          likes.value = ++likes.value
        } else {
          likes.value = --likes.value
        }
      }
    } else {
      navigateTo({ path: "/login", query: { redirectBackTo: route.fullPath } })
    }
  }

  const sortedMedia = computed(() => {
    const newMedia = []
    items.map((item) => {
      if (item.locked) {
        newMedia.push(item)
      } else {
        newMedia.unshift(item)
      }
    })
    return newMedia.slice(0, 4)
  })

  const mediaThumbClasses = computed((i) => {
    if (sortedMedia.value.length == 1) {
      return "aspect-[1/1] sm:aspect-[16/9] col-span-3"
    }

    if (sortedMedia.value.length == 2) {
      return "aspect-[1/1]"
    }

    if (sortedMedia.value.length == 3) {
      return "aspect-[1/1]"
    }

    return "aspect-[1/1]"
  })

  const lastClass = (index) => {
    if (index == 3) {
      return "aspect-[16/9] col-span-3 h-[250px] w-full"
    }
  }

  const config = useRuntimeConfig()
  const handlePurchase = async () => {
    userStore.getData()
    emit("broadcast-feed-update")
  }
</script>

<style lang="scss" scoped>
  /* TODO: There are a lot of link, sub-link styles laying around. I think just this part can be moved to main.scss for global use. Something like "popover-menu-item" */
  .link {
    @apply text-base w-full flex flex-row items-center gap-3 px-3 py-1.5 rounded-md text-cool-800 dark:text-white;

    .icon {
      @apply mx-auto h-5 w-5 lg:mx-0;
    }

    &:hover {
      @apply bg-cool-100 dark:bg-[#2B3236];
    }
  }
</style>
