<template>
  <Modal title="Add to Lists" @close="$emit('close')">
    <div v-for="list in tagLists.items">
      <div class="flex flex-col">
        <MediaLibrarySidebarRow
          :current-selection-key="currentSelectionKey"
          :list-key="list.key"
          :label="list.label"
          :image-count="list.imageCount"
          :video-count="list.videoCount"
          :preview-images="list.previewImages"
          :show-selector="true"
          :selected="isSelected(list)"
          @click="toggleSelected(list)"
        />
      </div>
    </div>

    <div class="flex flex-row items-center justify-between gap-1.5 mt-2">
      <Button theme="outline" @click="$emit('close')">Cancel</Button>
      <div class="flex flex-row items-center justify-end gap-2">
        <Button theme="outline" @click="newListModalShowing = true">
          New List
        </Button>
        <Button @click="addToSelectedLists">Add</Button>
      </div>
    </div>
  </Modal>

  <UModal
    v-model="newListModalShowing"
    :ui="{ container: 'items-start md:items-center' }"
  >
    <Modal title="Create New List" @close="newListModalShowing = false">
      <UForm class="flex flex-col gap-3 p-4" @submit="saveToNewList">
        <UFormGroup label="List Name">
          <UInput placeholder="List Name" v-model="newListLabel" />
        </UFormGroup>
        <div class="flex flex-row items-center justify-end gap-1.5">
          <Button theme="outline" @click="newListModalShowing = false">
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </div>
      </UForm>
    </Modal>
  </UModal>
</template>

<script setup>
  const props = defineProps({
    media: {
      type: Array,
      required: true,
    },

    tagLists: {
      type: Object,
      required: true,
    },
  })

  const emit = defineEmits(["close"])
  const listSelectionList = ref([])
  const newListModalShowing = ref(false)
  const newListLabel = ref("")
  const config = useRuntimeConfig()

  const toggleSelected = (list) => {
    if (isSelected(list)) {
      listSelectionList.value = listSelectionList.value.filter(
        (l) => l !== list,
      )
    } else {
      listSelectionList.value.push(list)
    }
  }

  const isSelected = (list) => {
    return listSelectionList.value.includes(list)
  }

  const previewImages = computed(() => {
    return props.media.map((media) => media.signedThumbnailUrl)
  })

  const addToSelectedLists = async () => {
    await useAuthFetch(`${config.public.API_URL}/api/media/bulk`, {
      method: "POST",
      body: {
        media_ids: props.media,
        new_tags: listSelectionList.value.map((list) => list.label),
      },
    })

    emit("close")
  }

  const saveToNewList = async () => {
    await useAuthFetch(`${config.public.API_URL}/api/media/bulk`, {
      method: "POST",
      body: {
        media_ids: props.media.map((m) => m.id),
        new_tags: newListLabel.value.split(","),
      },
    })

    newListModalShowing.value = false
    emit("close")
  }
</script>
