<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-row justify-between items-center text-sm">
      <span v-if="hasPaymentAccounts">Choose a Payment Method:</span>
      <span v-else>Add a Payment Method:</span>
      <a href="#" class="text-sm whitespace-nowrap" @click="handleNewCard">
        <font-awesome-icon icon="fa-light fa-plus" class="fa-fw" />
        New Card
      </a>
    </div>

    <!-- Select Payment Type -->
    <p
      v-if="
        purchaseForm.errors.paymentMethodSelected &&
        !purchaseForm.data.paymentMethodSelected
      "
      class="text-right text-xs text-red-500"
    >
      Must select credit / debit card
    </p>
    <div class="space-y-2">
      <div
        v-if="!hasPaymentAccounts"
        class="option-credit-card"
        :class="{
          'border-2 border-red-500 rounded-lg':
            purchaseForm.errors.paymentMethodSelected &&
            !purchaseForm.data.paymentMethodSelected,
        }"
        @click="handleSelectPaymentType('card')"
      >
        <span class="font-bold text-lg">Credit / Debit Card</span>
        <div class="flex flex-row items-center gap-1">
          <img width="35" height="24" src="/images/payment-visa-badge.png" />
          <img
            width="35"
            height="24"
            src="/images/payment-mastercard-badge.png"
          />
          <img width="35" height="24" src="/images/payment-ssl-badge.png" />
        </div>
      </div>
      <PurchaseModalPaymentMethodSelectorOption
        v-if="purchaseForm.data.paymentType == 'card'"
        :payment-accounts="paymentAccounts"
        :selected="selectedPaymentMethod"
        type="card"
        @option-selected="optionSelected"
      />
      <div
        v-if="availablePaymentMethods.includes('paypal')"
        class="option-paypal"
        @click="handleSelectPaymentType('paypal')"
      >
        <img
          width="100"
          height="24"
          src="/images/payment-paypal-logo.svg"
          class="mx-auto"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  const purchaseForm = inject("form")
  const moveToStep = inject("moveToStep")

  const props = defineProps({
    hasPaymentAccounts: Boolean,
    modelValue: String,
    availablePaymentMethods: Array,
    paymentAccounts: Array,
    errorMessage: String,
    initialStep: String,
  })

  const selectedPaymentMethod = ref(props.modelValue)
  const emit = defineEmits(["update:modelValue"])

  const optionSelected = (e) => {
    selectedPaymentMethod.value = e
    emit("update:modelValue", e)
  }

  const handleNewCard = () => {
    moveToStep({
      step: "enter-new-card",
      from: "subscription-choose-payment-account",
    })
  }

  const handleSelectPaymentType = (type) => {
    if (type === "paypal") {
      purchaseForm.data.paymentMethodSelected = null
    }
    purchaseForm.data.paymentType = type

    if (!props.hasPaymentAccounts && type == "card") {
      moveToStep({
        step: "enter-new-card",
        from: "subscription-choose-payment-account",
      })
    }
  }

  if (
    !props.availablePaymentMethods.includes("paypal") &&
    props.hasPaymentAccounts
  ) {
    purchaseForm.data.paymentType = "card"
  }
</script>

<style lang="scss" scoped>
  .option-credit-card {
    @apply flex flex-row items-center justify-between p-4 rounded-lg bg-cool-200 cursor-pointer;
    @apply hover:bg-cool-100 dark:bg-[#2B3236] dark:hover:bg-[#555F63];
  }
  .option-paypal {
    @apply flex flex-row items-center justify-between px-4 py-3 rounded-lg bg-cool-200 cursor-pointer;
    @apply hover:bg-cool-100 dark:bg-[#2B3236] dark:hover:bg-[#555F63];
  }
</style>
