<template>
  <div class="relative group">
    <div
      class="sm:hidden group-hover:block absolute -top-2 -right-2 z-50"
      @click="removeMedia"
    >
      <font-awesome-icon
        icon="fa-solid fa-circle-xmark"
        class="rounded-full text-lg text-gray-200 border-4 border-white dark:border-[#1B2022]"
      />
    </div>

    <div
      v-if="media.nsfw"
      class="absolute bottom-[-16px] left-[16px] text-[10px] text-[#595858] dark:text-cool-400"
    >
      NSFW
    </div>

    <div class="thumb">
      <div
        v-if="isLoading"
        class="w-[60px] h-[60px] bg-gray-300 animate-pulse"
      />
      <img
        v-else
        :src="media?.previewUrl || media?.signedThumbnailUrl"
        onerror="this.src='https://favoritely-assets.s3.us-west-1.amazonaws.com/staging/assets/photo_placeholders/250x250.png'"
        class="w-[60px] h-[60px] object-cover animate-fade"
      />
      <div class="wrapper">
        <div v-if="media.locked" class="lock locked">
          <font-awesome-icon
            :icon="media.nsfw ? 'fa-solid fa-lock' : 'fa-light fa-lock'"
          />
        </div>
        <div v-else class="lock unlocked">
          <font-awesome-icon icon="fa-light fa-lock-open" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    locked: Boolean,
    duration: String,
    media: Object,
  })

  const emit = defineEmits(["remove"])
  const removeMedia = (e) => {
    e.stopPropagation()
    emit("remove", props.media)
  }

  const isLoading = ref(true)
  const img = new Image()
  img.src = props.media?.previewUrl || props.media?.signedThumbnailUrl
  img.onload = () => {
    isLoading.value = false
  }
  img.onerror = () => {
    setTimeout(() => {
      isLoading.value = false
    }, 3000)
  }
</script>

<style lang="scss" scoped>
  .thumb {
    @apply relative text-white rounded-lg overflow-hidden;

    .wrapper {
      @apply absolute h-full inset-0 px-3 py-2 rounded-lg overflow-hidden bg-black bg-opacity-25 flex flex-col items-center justify-center transition-colors;

      &:hover {
        @apply bg-white bg-opacity-50;
      }
    }

    .lock {
      @apply h-full flex items-center justify-center rounded-full;

      aspect-ratio: 1/1;

      &.locked {
        @apply bg-pink-600 bg-opacity-75;
      }

      &.unlocked {
        @apply bg-black bg-opacity-75;
      }
    }
  }
</style>
