<template>
  <div v-if="userStore.approvedCreator && !profileComplete">
    <ProgressBar
      :zero-index="false"
      :current-step="currentProfileCompletionStep"
      :total-steps="totalProfileCompletionSteps"
      title="Complete Profile"
    >
      <p class="text-sm">
        Complete your profile for higher engagement. We will not be able to
        feature incomplete profiles on the homepage and in our suggested
        sections.
        <NuxtLink to="/settings/profile">Click here</NuxtLink>
      </p>
      <ul v-if="showItems">
        <li v-for="(v, k) in profileItemsToComplete">
          <font-awesome-icon
            icon="square-check"
            class="text-lg primary"
            v-if="v"
          />
          <font-awesome-icon icon="square" class="text-lg" v-if="!v" />
          <span class="ml-2">{{ k }}</span>
        </li>
      </ul>
    </ProgressBar>
  </div>
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const userStore = useUserStore()

  const {
    totalProfileCompletionSteps,
    currentProfileCompletionStep,
    profileItemsToComplete,
    profileComplete,
  } = useProfileCompletion()

  const props = defineProps({
    showItems: {
      type: Boolean,
      default: false,
    },
    zeroIndex: {
      type: Boolean,
      default: false,
    },
  })
</script>
