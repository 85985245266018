<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-row items-center justify-between gap-2">
      <div class="flex flex-row items-center gap-2">
        <font-awesome-icon v-if="isImage" icon="fa-light fa-image" />
        <font-awesome-icon v-else icon="fa-light fa-film" />
        <span class="text-sm leading-none">{{ upload.name }}</span>
      </div>
      <span class="text-sm leading-none">{{ upload.progress }}%</span>
    </div>
    <UProgress :value="upload.progress" />
  </div>
</template>

<script setup>
  const props = defineProps({
    upload: {
      type: Object,
      required: true,
    },
  })

  const isImage = computed(() => props.upload.type.startsWith("image"))
</script>
