<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-row items-center justify-between">
      <span class="text-sm sm:text-base dark:text-[#555F63]">
        Select a token amount:
      </span>
      <div class="flex flex-row items-center text-md">
        <font-awesome-icon
          icon="fa-light fa-coins"
          class="mr-2 dark:text-[#F2699B]"
        />
        <span class="text-sm sm:text-base">
          Balance:
          <span class="font-semibold">
            {{ $numberToHuman(userStore.tokenBalance) }}
          </span>
        </span>
      </div>
    </div>

    <div
      class="flex flex-row items-center justify-between p-4 rounded-xl border dark:border-[#F2699B]"
    >
      <Button
        size="lg"
        :iconOnly="true"
        theme="secondary"
        @click="lowerTokenAmount"
      >
        <font-awesome-icon icon="fa-solid fa-minus" class="dark:text-white" />
      </Button>
      <div class="flex flex-row items-center gap-1">
        <font-awesome-icon icon="fa-light fa-coins" class="text-lg" />
        <client-only>
          <div class="w-full text-center text-2xl font-bold">
            <NumberAnimation
              :from="0"
              :to="tokenAmount"
              :duration="0.25"
              :delay="0"
              :format="formatTokenAmount"
              autoplay
              easing="linear"
            />
          </div>
        </client-only>
      </div>
      <Button
        size="lg"
        :iconOnly="true"
        theme="secondary"
        @click="raiseTokenAmount"
      >
        <font-awesome-icon icon="fa-solid fa-plus" class="dark:text-white" />
      </Button>
    </div>

    <div class="flex flex-row items-center justify-between">
      <span
        v-for="amount in specificAmounts"
        :key="amount"
        class="text-xs px-3.5 py-1.5 font-bold rounded-full border border-cool-200 transition transition-colors cursor-pointer dark:border-[#394247] hover:bg-cool-100 hover:dark:bg-[#394247] sm:text-sm sm:px-5 sm:py-2"
        @click="setTokenAmount(amount)"
      >
        {{ $numberToHuman(amount) }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const props = defineProps({
    modelValue: Number,
  })

  const emit = defineEmits(["token-count-updated"])

  const userStore = useUserStore()
  const minimumTokenAmount = 100
  const maxTokenAmount = 1000
  const tokenAmount = ref(props.modelValue)
  const specificAmounts = [100, 250, 500, 750, 1000]

  const formatTokenAmount = (value) => {
    const numberToHuman = (num) => {
      if (num == 0) {
        return 0
      } else if (num < 1000) {
        return num.toString()
      } else if (num < 1000000) {
        return (num / 1000).toFixed(1) + "K"
      } else if (num < 1000000000) {
        return (num / 1000000).toFixed(1) + "M"
      } else {
        return (num / 1000000000).toFixed(1) + "B"
      }
    }
    return numberToHuman(Math.floor(value))
  }

  const lowerTokenAmount = () => {
    if (tokenAmount.value > 0 && tokenAmount.value > minimumTokenAmount) {
      tokenAmount.value--
      emit("token-count-updated", tokenAmount.value)
    }
  }

  const raiseTokenAmount = () => {
    if (tokenAmount.value < maxTokenAmount) {
      tokenAmount.value++
      emit("token-count-updated", tokenAmount.value)
    }
  }

  const setTokenAmount = (amount) => {
    tokenAmount.value = amount
    emit("token-count-updated", tokenAmount.value)
  }
</script>
