// src/composables/useBackgroundImageLoader.ts
import { ref, watch, onMounted, onUnmounted, Ref } from "vue"

interface UseBackgroundImageLoaderProps {
  imageUrl: string
  placeholder: string
  retryInterval: number
  maxRetries: number
}

interface UseBackgroundImageLoaderReturn {
  currentBgImage: Ref<string>
  hasError: Ref<boolean>
  isLoading: Ref<boolean>
  retryCount: Ref<number>
}

export function useBackgroundImageLoader(
  props: UseBackgroundImageLoaderProps,
): UseBackgroundImageLoaderReturn {
  const { imageUrl, placeholder, retryInterval, maxRetries } = props

  const currentBgImage = ref<string>(imageUrl)
  const hasError = ref<boolean>(false)
  const retryCount = ref<number>(0)
  const isLoading = ref<boolean>(true)

  const getRetryInterval = (count: number): number => {
    return retryInterval * Math.log(count + 2)
  }

  let retryTimer: number | null = null

  const attemptLoad = () => {
    isLoading.value = true
    const img = new Image()
    img.src = imageUrl
    img.onload = () => {
      hasError.value = false
      currentBgImage.value = imageUrl
      isLoading.value = false
      stopRetry()
      retryCount.value = 0
    }
    img.onerror = () => {
      hasError.value = true
      currentBgImage.value = placeholder
      isLoading.value = false
      if (retryCount.value < maxRetries) {
        startRetry()
      }
      // Note: Emitting events should be handled in the component
    }
  }

  const nextTrigger = (count: number): number => {
    return Date.now() + getRetryInterval(count)
  }

  const debugging = false
  function debugMessage(message) {
    if (debugging) {
      console.log(message)
    }
  }

  const startRetry = () => {
    if (retryTimer === null && retryCount.value < maxRetries) {
      let trigger = nextTrigger(retryCount.value)
      retryTimer = window.setInterval(() => {
        if (Date.now() > trigger) {
          retryCount.value += 1
          debugMessage(
            `Retrying to load bg image (${retryCount.value}/${maxRetries}): ${imageUrl}`,
          )
          trigger = nextTrigger(retryCount.value)
          attemptLoad()
          if (retryTimer) {
            debugMessage(
              `Next reload in ${trigger - new Date().getTime()} milliseconds`,
            )
          }
        }
      }, 100) // Reduced interval for efficiency
    }
  }

  const stopRetry = () => {
    if (retryTimer !== null) {
      clearInterval(retryTimer)
      retryTimer = null
    }
  }

  watch(
    () => props.imageUrl,
    (newUrl) => {
      currentBgImage.value = newUrl
      hasError.value = false
      isLoading.value = true
      retryCount.value = 0
      stopRetry()
      attemptLoad()
    },
  )

  onMounted(() => {
    attemptLoad()
  })

  onUnmounted(() => {
    stopRetry()
  })

  return {
    currentBgImage,
    hasError,
    isLoading,
    retryCount,
  }
}
