<template>
  <div class="w-full cursor-pointer">
    <UInput
      ref="searchInput"
      autocomplete="off"
      class="w-full border-2 border-gray-200 rounded-full dark:border-gray-900"
      icon="i-heroicons-magnifying-glass-20-solid"
      :placeholder="placeholder"
      v-model="inputValue"
      variant="none"
      @click="handler"
      :ui="{
        padding: { sm: 'px-2.5 py-2.5' },
        icon: { trailing: { pointer: '' } },
      }"
    >
      <template #trailing>
        <UButton
          v-show="inputValue"
          color="gray"
          variant="link"
          icon="i-heroicons-x-mark-20-solid"
          :padded="false"
          @click="inputValue = null"
        />
      </template>
    </UInput>
  </div>
</template>

<script setup lang="ts">
  import { useUserStore } from "@/store/user"

  const userStore = useUserStore()
  const loginShowing = inject("loginModalOpen")
  const searchInput = ref(null)
  const { focused } = useFocus(searchInput)

  const handleQueryChange = inject("handleGlobalSearchQueryChange")

  const props = defineProps({
    placeholder: "Search&hellip;",
    query: {
      type: String,
      default: null,
    },
  })

  const emit = defineEmits(["update:modelValue"])

  const inputValue = computed({
    get() {
      return props.query
    },

    set(value: string) {
      handleQueryChange(value)
    },
  })

  const handler = () => {
    if (!userStore.loggedIn) {
      loginShowing.value = true
      focused.value = false
    }
  }
</script>
